<template>
  <div class="content-wrapper" style="min-height: 1246.8px;">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 v-if="id">Update Selected a Template</h1>
            <h1 v-else>Select a Template</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="'/home'">Home</router-link>
              </li>

              <li v-if="id" class="breadcrumb-item active">
                Selected Template Editing Form
              </li>
              <li v-else class="breadcrumb-item active">
                Template Selection Form
              </li>
            </ol>
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <!-- left column -->
          <div class="col-md-6">
            <!-- general form elements -->
            <div class="card card-primary">
              <div class="card-header bg-info">
                <h3 class="card-title">Template Selection</h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <form v-on:submit.prevent="submitForm" role="form">
                <div class="card-body">
                  <div class="form-group">
                    <label for="exampleInputName">Template Name</label>
                    <input
                      disabled
                      required
                      v-model="selectedTemplate.name"
                      type="text"
                      class="form-control"
                      :class="errors.template_id ? 'is-invalid' : ''"
                      id="exampleInputName"
                      placeholder="Template Name"
                    />
                    <div class="invalid-feedback">
                      <span
                        v-if="errors.template_id && errors.template_id[0]"
                        >{{ errors.template_id[0] }}</span
                      >
                    </div>
                  </div>

                  <div>
                    <label for="color-pick-primary">Pick Primary Colors</label>
                    <input
                      id="color-pick-primary"
                      type="color"
                      :class="errors.primary_color ? 'is-invalid' : ''"
                      v-model="primaryColor"
                    />
                    <div class="invalid-feedback">
                      <span
                        v-if="errors.primary_color && errors.primary_color[0]"
                        >{{ errors.primary_color[0] }}</span
                      >
                    </div>
                  </div>

                  <div>
                    <label for="color-pick-secondary"
                      >Pick Secondary Colors</label
                    >
                    <input
                      id="color-pick-secondary"
                      type="color"
                      :class="errors.secondary_color ? 'is-invalid' : ''"
                      v-model="secondaryColor"
                    />
                    <div class="invalid-feedback">
                      <span
                        v-if="errors.secondary_color && errors.secondary_color[0]"
                        >{{ errors.secondary_color[0] }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div v-if="working" class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <br />
                  <button
                    :disabled="working"
                    type="submit"
                    id="submit-btn"
                    class="btn bg-info"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
            <!-- /.card -->
          </div>
          <!--/.col (left) -->
          <!-- right column -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ShopTemplateForm",
  data() {
    return {
      primaryColor: "#5069ff",
      secondaryColor: "#c7c2ff",
      working: false,
      errors: [],
    };
  },
  props: {
    id: {
      default: null,
    },
    templateId: {
      default: null,
    },
  },
  watch: {
    shopTemplate() {
      if (this.shopTemplate.id) {
        this.setData();
      } else {
        this.resetData();
      }
    },
  },
  computed: {
    ...mapGetters([
      "shopTemplate",
      "template",
      "shop",
      "displayErrorMessages",
    ]),
    selectedTemplate() {
      if (this.templateId) {
        return this.template;
      } else if (this.id && this.shopTemplate.id) {
        return this.shopTemplate.template;
      } else {
        return {};
      }
    },
  },

  async mounted() {
    this.setData();
    if (this.templateId) {
      await this.fetchTemplate(this.templateId);
    }
    if (this.id) {
      await this.fetchShopTemplate(this.id);
    }
  },
  methods: {
    ...mapActions([
      "selectShopTemplate",
      "updateShopTemplate",
      "fetchTemplate",
      "fetchShopTemplate",
    ]),
    async submitForm() {
      if (!this.working) {
        this.working = true;

        let formData = new FormData();
        let pass = true;

        formData.append("primary_color", this.primaryColor);
        formData.append("secondary_color", this.secondaryColor);

        if (pass) {
          if (this.id) {
            formData.append("template_id", this.templateId);
            formData.append("_method", "PUT");
            await this.updateShopTemplate({ formData: formData, id: this.id })
              .then((response) => {
                this.$toastr.s("Selected Template Updated Successfully");
                this.$router
                  .push("/show/template/" + this.selectedTemplate.id)
                  .catch((e) => {
                    console.log(e);
                  });
              })
              .catch((error) => {
                this.errors = this.displayErrorMessages(error);
              });
          } else {
            formData.append("template_id", this.templateId);
            formData.append("shop_id", this.shop.id);
            await this.selectShopTemplate(formData)
              .then((response) => {
                this.$toastr.s("Template Selected Successfully");
                this.$router
                  .push("/show/template/" + this.template.id)
                  .catch((e) => {
                    console.log(e);
                  });
              })
              .catch((error) => {
                this.errors = this.displayErrorMessages(error);
              });
          }
        }

        this.working = false;
      }
    },

    setData() {
      if (this.id && this.shopTemplate.id) {
        this.primaryColor = this.shopTemplate.primary_color;
        this.secondaryColor = this.shopTemplate.secondary_color;
      }
    },

    resetData() {
      this.primaryColor = "";
      this.secondaryColor = "";
    },
  },
};
</script>

<style scoped></style>
