<template>
  <div v-if="shop.id">
    <div class="content-wrapper">
      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0 text-dark">Shop</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item">
                  <router-link :to="'/home'">Home</router-link>
                </li>
                <li class="breadcrumb-item active">Shop</li>
              </ol>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </section>

      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-4">
              <!-- Widget: user widget style 2 -->
              <div class="card card-widget widget-user-2">
                <!-- Add the bg color to the header using any of the bg-* classes -->
                <div class="widget-user-header bg-dark">
                  <div class="widget-user-image">
                    <img
                      v-if="shop.logo"
                      class="img-circle elevation-2"
                      :src="serverUrl + shop.logo.storageUrl"
                      alt="Shop Logo"
                    />
                    <img
                      v-else
                      class="img-circle elevation-2"
                      src=""
                      alt="Shop Logo"
                    />
                  </div>
                  <!-- /.widget-user-image -->
                  <h3 class="widget-user-username">
                    {{ shop.shop_name
                    }}<span class="float-right"
                      ><router-link
                        :to="'/edit/shop/'"
                        class="btn btn-sm btn-secondary"
                      >
                        <i class="fas fa-edit"></i></router-link
                    ></span>
                  </h3>
                </div>
                <div class="card-footer p-0">
                  <ul class="nav flex-column">
                      
                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Shop ID</span>
                        <span class="float-right">{{ shop.id }}</span>
                      </span>
                    </li>

                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Shop Name</span>
                        <span class="float-right">{{ shop.shop_name }}</span>
                      </span>
                    </li>

                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Shop Url</span>
                        <span class="float-right">{{ shop.shop_url }}</span>
                      </span>
                    </li>

                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Description:</span>
                        <span>{{ shop.description }}</span>
                      </span>
                    </li>

                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Email</span>
                        <span class="float-right ">{{ shop.email }}</span>
                      </span>
                    </li>
                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Phone Number</span>
                        <span class="float-right ">{{
                          shop.phone_number
                        }}</span>
                      </span>
                    </li>

                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Date Added</span>
                        <span class="float-right ">{{
                          getDate(shop.created_at)
                        }}</span>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- /.widget-user -->
            </div>

            <ShopAddress :address="shop.address"></ShopAddress>

            <AdvertInfo
              :type="'shop'"
              :shop-owner="true"
              :advertable="shop"
              @set-advert="setShopAdvert"
              @remove-advert="removeShopAdvert"
            ></AdvertInfo>
          </div>
        </div>
      </section>
      <ShopContacts :contacts="shop.contacts"></ShopContacts>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AdvertInfo from "./AdvertInfo";
import ShopAddress from "./ShopAddress";
import ShopContacts from "./ShopContacts";

export default {
  name: "ShopShow",
  components: {
    AdvertInfo,
    ShopAddress,
    ShopContacts,
  },
  computed: {
    ...mapGetters(["getDate", "serverUrl", "shop"]),
  },
  mounted() {
    this.fetchShop();
  },
  props: {},
  methods: {
    ...mapActions(["setShopAdvert", "removeShopAdvert", "fetchShop"]),
  },
};
</script>

<style scoped></style>
