<template>
    <div class="text-center">
        <h1 class="title">Sorry. Page Not Found :(</h1>
    </div>
</template>
<script>
    export default {
        name: 'NotFound',
    }
</script>
<style scoped>
</style>