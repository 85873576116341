import Vue from 'vue'
import Vuex from 'vuex'
import accessory from './modules/accessory';
import auth from './modules/auth';
import shopOwner from './modules/shop_owner';
import shop from './modules/shop';
import category from './modules/category';
import product from './modules/product';
import tag from './modules/tag';
import service from './modules/service';
import order from './modules/order';
import template from './modules/template';
import shopTemplate from './modules/shop_template';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  getters: {
  },
  modules: {
    accessory,
    auth,
    shopOwner,
    shop,
    category,
    product,
    tag,
    service,
    order,
    template,
    shopTemplate
  }
})
