<template>
    <ShopForm :edit="true"></ShopForm>
</template>

<script>
    import ShopForm from './ShopForm'
    export default {
  data () {
    return {
    }
  },
        name: "EditAbout",
        components: {
            ShopForm
        }
    }
</script>

<style scoped>

</style>