<template>
  <div v-if="shop.id">
    <div
      v-if="!shop.contact"
      class="content-wrapper"
      style="min-height: 1246.8px;"
    >
      <!-- Content Header (Page header) -->
      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 v-if="edit">Edit Contact</h1>
              <h1 v-else>Add Contact</h1>
            </div>
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item">
                  <router-link :to="'/home'">Home</router-link>
                </li>
                <li class="breadcrumb-item active">Add Contact</li>
              </ol>
            </div>
          </div>
        </div>
        <!-- /.container-fluid -->
      </section>

      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <!-- left column -->
            <div class="col-md-6">
              <!-- general form elements -->
              <div class="card card-primary">
                <div class="card-header bg-info">
                  <h3 class="card-title">Contact Form</h3>
                </div>
                <!-- /.card-header -->
                <!-- form start -->
                <form v-on:submit.prevent="submitForm" role="form">
                  <div class="card-body">
                    <div class="form-group">
                      <label class="typo__label">Media</label>
                      <multi-select
                        v-model="contact"
                        deselect-label="Can't remove this value"
                        track-by="name"
                        label="name"
                        placeholder="Select one"
                        :options="contacts"
                        :searchable="true"
                        :allow-empty="false"
                      >
                        <template
                          slot="singleLabel"
                          slot-scope="{ option }"
                        ></template>
                      </multi-select>
                    </div>

                    <slot v-if="contact.id">
                      <div class="form-group">
                        <label for="basic-url">Username</label>
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon3"
                              >https://{{ contact.url }}/</span
                            >
                            <input
                              v-model="username"
                              type="text"
                              class="form-control"
                              :class="errors.username ? 'is-invalid' : ''"
                              id="basic-url"
                              placeholder="username"
                              aria-describedby="basic-addon3"
                            />
                            <div class="invalid-feedback">
                              <span
                                v-if="errors.username && errors.username[0]"
                                >{{ errors.username[0] }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </slot>
                  </div>
                  <!-- /.card-body -->

                  <div class="card-footer">
                    <div v-if="working" class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                    <br />
                    <button
                      :disabled="working"
                      type="submit"
                      id="submit-btn"
                      class="btn bg-info"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
              <!-- /.card -->
            </div>
            <!--/.col (left) -->
            <!-- right column -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </section>
      <!-- /.content -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ContactForm",
  data() {
    return {
      contact: {},
      username: "",
      working: false,
      errors: [],
    };
  },
  props: {
    edit: {
      default: false,
    },
  },
  computed: {
    ...mapGetters(["shop", "contacts", "displayErrorMessages"]),
  },

  mounted() {
    this.fetchContacts();
  },

  methods: {
    ...mapActions(["storeContact", "fetchContacts"]),
    async submitForm() {
      if (!this.working) {
        this.working = true;

        let formData = new FormData();

        if (this.contact.id) {
          formData.append("contact_id", this.contact.id);
        }

        formData.append("username", this.username);
        formData.append("shop_id", this.shop.id);

        await this.storeContact(formData)
          .then((response) => {
            this.$toastr.s("Contact Added Successfully");
            this.$router.push("/show/shop/").catch((e) => {
              console.log(e);
            });
          })
          .catch((error) => {
            this.errors = this.displayErrorMessages(error);
          });

        this.working = false;
      }
    },
  },
};
</script>

<style scoped></style>
