import axios, * as others from "../../../axios";
import router from '../../../router'

const state = {
  shopOwner: {},
};

const getters = {
  shopOwner: (state) => state.shopOwner,
};

const actions = {
  async fetchShopOwner({ commit,dispatch }) {
    state.shopOwner = {};
    const response = await axios
      .get("/shop-owner/profile/")
      .catch(function(error) {
        console.log(error);
        localStorage.removeItem("batelew-token")
        router.push('/login');
      });

    

    commit("SET_SHOP_OWNER", response.data);
    dispatch('fetchShop')
  },
};

const mutations = {
  SET_SHOP_OWNER: (state, shopOwner) => (state.shopOwner = shopOwner),
};

const Module = {
  state,
  mutations,
  actions,
  getters,
};

export default Module;
