import axios, * as others from "../../../axios";
import * as Vue from "vue";

const state = {
  tags: [],
  tag: {},
};
const mutations = {
  SET_TAGS: (state, payload) => (state.tags = payload),
  SET_TAG: (state, payload) => (state.tag = payload),
  UPDATE_TAG: (state, tag) => {
    const i = state.tags
      .map((tag) => tag.id)
      .indexOf(tag.id);
    state.tags.splice(i, 1);
    state.tags.splice(i, 0, tag);
  },
  DELETE_TAG: (state, id) => {
    const i = state.tags.map((tag) => tag.id).indexOf(id);
    state.tags.splice(i, 1);
  },
  SET_ADVERT: (state, advert) => (state.tag.advert = advert),
  REMOVE_ADVERT: (state) => (state.tag.advert = null),
};
const actions = {
  fetchTags({ commit }) {
  
    axios
      .get("/shop-owner/tags/")
      .then((response) => {
        commit("SET_TAGS", response.data);
      })
      .catch((error) => {
        const response = error.response;
        response && response.data
          ? this._vm.$toastr.e(response.data.message)
          : console.log(error);
      });
  },
  fetchCategoryTags({ commit }, id) {
    axios
      .get("/shop-owner/categories/" + id + "/tags")
      .then((response) => {
        commit("SET_TAGS", response.data);
      })
      .catch((error) => {
        const response = error.response;
        response && response.data
          ? this._vm.$toastr.e(response.data.message)
          : console.log(error);
      });
  },
  fetchTag({ commit }, id) {
    state.tag = {};
    axios
      .get("/shop-owner/tags/" + id)
      .then((response) => {
        commit("SET_TAG", response.data);
      })
      .catch((error) => {
        const response = error.response;
        response && response.data
          ? this._vm.$toastr.e(response.data.message)
          : console.log(error);
      });
  },

  async storeTag({ commit }, formData) {
    return new Promise((resolve, reject) => {
      axios
        .post("/shop-owner/tags", formData)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          error.response ? reject(error.response) : console.log(error);
        });
    });
  },

  async updateTag({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/shop-owner/tags/" + data.id, data.formData)
        .then((response) => {
          commit("UPDATE_TAG", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          error.response ? reject(error.response) : console.log(error);
        });
    });
  },

  async deleteTag({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/shop-owner/tags/" + id)
        .then((response) => {
          commit("DELETE_TAG", id);
          resolve(response.data);
        })
        .catch((error) => {
          error.response ? reject(error.response) : console.log(error);
        });
    });
  },

  setTagAdvert({ commit }, advert) {
    commit("SET_ADVERT", advert);
  },

  removeTagAdvert({ commit }) {
    commit("REMOVE_ADVERT");
  },
};
const getters = {
  tags: (state) => state.tags,
  tag: (state) => state.tag,
};

const tagModule = {
  state,
  mutations,
  actions,
  getters,
};

export default tagModule;
