<template>
  <div v-if="service.id">
    <div class="content-wrapper">
      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0 text-dark">Service</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item">
                  <router-link :to="'/home'">Home</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link :to="'/list/service'">Service List</router-link>
                </li>
                <li class="breadcrumb-item active">{{ service.name }}</li>
              </ol>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </section>

      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <!-- Widget: user widget style 2 -->
              <div class="card card-widget widget-user-2">
                <!-- Add the bg color to the header using any of the bg-* classes -->
                <div class="widget-user-header bg-dark">
                  <div class="widget-user-image">
                    <img
                      v-if="service.images[0]"
                      class="img-circle elevation-2"
                      :src="serverUrl + service.images[0].storageUrl"
                      alt="Service Image"
                    />
                    <img
                      v-else
                      class="img-circle elevation-2"
                      src=""
                      alt="Service Image"
                    />
                  </div>
                  <!-- /.widget-user-image -->
                  <h3 class="widget-user-username">
                    {{ service.name
                    }}<span class="float-right"
                      ><router-link
                        :to="'/edit/service/' + service.id"
                        class="btn btn-sm btn-secondary"
                      >
                        <i class="fas fa-edit"></i></router-link
                      ><button
                        v-on:click="removeService(service.id)"
                        class="btn btn-sm btn-outline-danger"
                      >
                        <i class="fas fa-trash"></i></button
                    ></span>
                  </h3>
                </div>
                <div class="card-footer p-0">
                  <ul class="nav flex-column">
                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Service ID</span>
                        <span class="float-right">{{ service.id }}</span>
                      </span>
                    </li>

                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Service Name</span>
                        <span class="float-right">{{ service.name }}</span>
                      </span>
                    </li>

                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Description:</span>
                        <span>{{ service.description }}</span>
                      </span>
                    </li>
                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Price:</span>
                        <span class="float-right "
                          >{{ service.price }} ETB</span
                        >
                      </span>
                    </li>

                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Discount:</span>
                        <span class="float-right "
                          >{{ service.discount }} ETB ({{
                            (
                              (service.discount / service.price) *
                              100
                            ).toFixed()
                          }}%)</span
                        >
                      </span>
                    </li>

                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Time Unit:</span>
                        <span class="float-right ">{{ service.timeUnit }}</span>
                      </span>
                    </li>

                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Date Added</span>
                        <span class="float-right ">{{
                          getDate(service.created_at)
                        }}</span>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- /.widget-user -->
            </div>
            <div class="col-md-6">
              <ImageCarousel :images="service.images"></ImageCarousel>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ImageCarousel from "./ImageCarousel";

export default {
  name: "ServiceShow",
  components: {
    ImageCarousel,
  },
  computed: {
    ...mapGetters(["shopOwner", "getDate", "service", "serverUrl"]),
  },
  props: {
    id: {
      required: true,
    },
  },
  mounted() {
    this.fetchService(this.id);
  },
  methods: {
    ...mapActions([
      "fetchService",
      "deleteService",
      "showSuccessFlashMessage",
      "showFlashMessage",
    ]),
    async removeService(id) {
      bootbox.confirm(
        "Are you sure to delete the service and all its related data?",
        async (result) => {
          if (result) {
            await this.deleteService(id)
              .then((res) => {
                this.$toastr.s("Service removed successfully.");
                this.$router.push("/list/service").catch((err) => {});
              })
              .catch((err) => {
                this.$toastr.e(err);
              });
          }
        }
      );
    },
  },
};
</script>

<style scoped></style>
