import axios, * as others from "../../../axios";
import * as Vue from "vue";

const state = {
  shops: [],
  shop: {},
  contacts: [],
};
const mutations = {
  SET_SHOPS: (state, payload) => (state.shops = payload),
  SET_SHOP: (state, payload) => (state.shop = payload),
  UPDATE_SHOP: (state, shop) => {
    //TODO: check if it don't cause issues if the shops are not set
    // like if we go directly to the shop show page
    const i = state.shops.map((shop) => shop.id).indexOf(shop.id);
    state.shops.splice(i, 1);
    state.shops.splice(i, 0, shop);
  },
  DELETE_SHOP: (state, id) => {
    const i = state.shops.map((shop) => shop.id).indexOf(id);
    state.shops.splice(i, 1);
  },
  DELETE_CONTACT: (state, id) => {
    const i = state.shop.contacts.map((contact) => contact.id).indexOf(id);
    state.shop.contacts.splice(i, 1);
  },
  SET_CONTACTS: (state, payload) => (state.contacts = payload),
  SET_ADVERT: (state, advert) => (state.shop.advert = advert),
  REMOVE_ADVERT: (state) => (state.shop.advert = null),
};
const actions = {
  fetchShops({ commit }, payload) {
    console.log(payload);
    axios
      .get("/shop-owner/shops/" + payload)
      .then((response) => {
        commit("SET_SHOPS", response.data);
      })
      .catch((error) => {
        const response = error.response;
        response && response.data
          ? this._vm.$toastr.e(response.data.message)
          : console.log(error);
      });
  },

  fetchShop({ commit }, id = '') {
    state.shop = {};
    axios
      .get("/shop-owner/shops/" + id)
      .then((response) => {
        commit("SET_SHOP", response.data);
      })
      .catch((error) => {
        console.log(error.response.data);
        
        const response = error.response;
        response
          ? this._vm.$toastr.e(response.data.message)
          : console.log(error);
          
      });
  },

  async storeShop({ commit,dispatch }, formData) {
    return new Promise((resolve, reject) => {
      axios
        .post("/shop-owner/shops", formData)
        .then((response) => {
          dispatch('fetchShopOwner');
          resolve(response.data);
        })
        .catch((error) => {
          error.response ? reject(error.response) : console.log(error);
        });
    });
  },

  async updateShop({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/shop-owner/shops/" + data.id, data.formData)
        .then((response) => {
          commit("UPDATE_SHOP", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          error.response ? reject(error.response) : console.log(error);
        });
    });
  },

  async deleteShop({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/shop-owner/shops/" + id)
        .then((response) => {
          commit("DELETE_SHOP", id);
          resolve(response.data);
        })
        .catch((error) => {
          error.response ? reject(error.response) : console.log(error);
        });
    });
  },

  setShopAdvert({ commit }, advert) {
    commit("SET_ADVERT", advert);
  },

  removeShopAdvert({ commit }) {
    commit("REMOVE_ADVERT");
  },

  //Address

  async storeAddress({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/shop-owner/addresses", payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          error.response ? reject(error.response) : console.log(error);
        });
    });
  },
  async updateAddress({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/shop-owner/addresses/" + payload.id, payload.formData)
        .then((response) => {
          resolve({ id: response.data.id });
        })
        .catch((error) => {
          error.response ? reject(error.response) : console.log(error);
        });
    });
  },

  //Contact

  async storeContact({ commit }, formData) {
    return new Promise((resolve, reject) => {
      axios
        .post("/shop-owner/contacts", formData)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          error.response ? reject(error.response) : console.log(error);
        });
    });
  },
  async deleteContact({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/shop-owner/contacts/" + id)
        .then((response) => {
          commit("DELETE_CONTACT", id);
          resolve(response.data);
        })
        .catch((error) => {
          error.response ? reject(error.response) : console.log(error);
        });
    });
  },
  async fetchContacts({ commit }) {
    const response = await axios
      .get("/shop-owner/contacts")
      .catch(function(error) {
        error.response ? reject(error.response) : console.log(error);
      });

    commit("SET_CONTACTS", response.data);
  },
};
const getters = {
  shops: (state) => state.shops,
  shop: (state) => state.shop,
  contacts: (state) => state.contacts,
};

const shopModule = {
  state,
  mutations,
  actions,
  getters,
};

export default shopModule;
