import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import NotFound from '../views/NotFound.vue'



import CategoryList from '../components/ListCategory'
import CategoryShow from '../components/ShowCategory'
import CategoryForm from '../components/CategoryForm'

import ProductShow from '../components/ShowProduct'
import ProductForm from '../components/ProductForm'

import TagList from '../components/ListTag'
import TagShow from '../components/ShowTag'
import TagForm from '../components/TagForm'

import ServiceList from '../components/ListService'
import ServiceShow from '../components/ShowService'
import ServiceForm from '../components/ServiceForm'

import Orders from '../components/ListOrder'

import ShowShop from '../components/ShowShop'
import ShopForm from '../components/ShopForm'
import EditShop from '../components/EditShop'

import ShowTemplate from '../components/ShowTemplate'
import ListTemplate from '../components/ListTemplate'
import ShopTemplateForm from '../components/ShopTemplateForm'

import AddressForm from '../components/AddressForm'
import EditAddress from '../components/EditAddress'

import ContactForm from '../components/ContactForm'

import GeneralStats from '../components/stats/GeneralStats'

import auth from '../api/auth'



Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'Home',
    component: GeneralStats,
    children: [
      
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/stats',
    name: 'GeneralStat',      
    component: GeneralStats,
    },
  {
    path: '/list/category/',
    component: CategoryList,
    props: true

  },
  {
      path: '/list/tag/',
      component: TagList,
      props: true

  },
  {
      path: '/list/service/',
      component: ServiceList,
      props: true

  },
  {
      path: '/show/category/:id',
      component: CategoryShow,
      props: true

  },
  {
      path: '/show/tag/:id',
      component: TagShow,
      props: true

  },
  {
      path: '/show/service/:id',
      component: ServiceShow,
      props: true

  },
  {
      path: '/list/order',
      component: Orders,
      props: true

  },
  {
      path: '/list/template',
      component: ListTemplate,
      props: true

  },
  {
      path: '/show/template/:id',
      component: ShowTemplate,
      props: true

  },
  {
      path: '/select/template/:templateId',
      component: ShopTemplateForm,
      props: true

  },
  {
      path: '/edit/shop-template/:id',
      component: ShopTemplateForm,
      props: true

  },
  {
      path: '/show/shop',
      component: ShowShop,
      props: true

  },
  {
      path: '/add/shop',
      name: 'add-shop',
      component: ShopForm,
      props: true

  },
  {
      path: '/edit/shop/',
      component: EditShop,
      props: true

  },
  {
      path: '/add/address/',
      component: AddressForm,
      props: true

  },
  {
      path: '/edit/address/',
      component: EditAddress,
      props: true

  },
  {
      path: '/add/contact',
      component: ContactForm,
      props: true

  },
  {
      path: '/show/product/:id',
      component: ProductShow,
      props: true

  },
  {
      path: '/add/category',
      component: CategoryForm,
      props: true

  },
  {
      path: '/edit/category/:id',
      component: CategoryForm,
      props: true

  },
  {
      path: '/add/tag',
      component: TagForm,
      props: true

  },
  {
      path: '/edit/tag/:id',
      component: TagForm,
      props: true

  },
  {
      path: '/add/service',
      component: ServiceForm,
      props: true

  },
  {
      path: '/edit/service/:id',
      component: ServiceForm,
      props: true

  },
  {
      path: '/add/product/category/:categoryId',
      component: ProductForm,
      props: true

  },
  {
      path: '/add/product/tag/:tagId',
      component: ProductForm,
      props: true

  },
  {
      path: '/add/product/category/:categoryId/tag/:tag-id',
      component: ProductForm,
      props: true

  },
  {
      path: '/edit/product/:id',
      component: ProductForm,
      props: true

  },
  
  {
      path: '/shop-owner/home',
      component: GeneralStats,
      props: true

  },
  {
      path: '/home',
      component: GeneralStats,
      props: true

  },
  {
    path: '*',
    Component: NotFound
    }

  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
    if((to.name !== 'Login' && to.name !== 'Register') && localStorage.getItem('batelew-token')===null){
        next({name: 'Login'})
    }else if((to.name === 'Login' || to.name === 'Register') && localStorage.getItem('batelew-token') ){
        next('/home')
    }else{
        next()
    }

})

export default router
