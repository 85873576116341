<template>
    <div class="col-md-4">
        <!-- Widget: user widget style 2 -->
        <div class="card card-widget widget-user-2">
            <!-- Add the bg color to the header using any of the bg-* classes -->
            <div class="widget-user-header bg-info">
                <!-- /.widget-user-image -->
                <h4>Address
                    <router-link v-if="address" :to="'/edit/address'" class="btn btn-sm btn-secondary float-right"><i data-v-f24113d4="" class="fas fa-edit"></i></router-link>
                    <router-link v-else :to="'/add/address/'" class="btn btn-sm btn-secondary float-right"><i data-v-f24113d4="" class="fas fa-plus"></i></router-link>
                </h4>
            </div>
            <div class="card-footer p-0" v-if="address">
                <ul class="nav flex-column">

                    <li class="nav-item">
                                            <span href="#" class="nav-link">
                                                <span class="text-bold">Country</span> <span class="float-right">{{address.country}}</span>
                                            </span>
                    </li>
                    <li class="nav-item">
                                            <span href="#" class="nav-link">
                                                <span class="text-bold">City</span> <span class="float-right">{{address.city}}</span>
                                            </span>
                    </li>
                    <li class="nav-item">
                                            <span href="#" class="nav-link">
                                                <span class="text-bold">Sub City</span> <span class="float-right">{{address.sub_city}}</span>
                                            </span>
                    </li>

                    <li class="nav-item">
                                            <span href="#" class="nav-link">
                                                <span class="text-bold">Description:</span> <span>{{address.description}}</span>
                                            </span>
                    </li>

                </ul>
            </div>
        </div>
        <!-- /.widget-user -->
    </div>
</template>

<script>
    export default {
        name: "AboutAddress",
        props:{
            address:{
                required: true,
            }
        }
    }
</script>

<style scoped>

</style>