import axios, * as others from "../../../axios";
import * as Vue from "vue";

const state = {
  shopTemplate: {},
};
const mutations = {
  SET_SHOP_TEMPLATE: (state, payload) => (state.shopTemplate = payload),
};
const actions = {
  async fetchShopTemplate({ commit }, id) {
    state.shopTemplate = {};
    const response = await axios
      .get("/shop-owner/shop-templates/" + id)
      .catch(function(error) {
        const response = error.response;
        response && response.data
          ? this._vm.$toastr.e(response.data.message)
          : console.log(error);
      });

    commit("SET_SHOP_TEMPLATE", response.data);
  },

  async fetchShopTemplateByShop({ commit }, id) {
    state.shopTemplate = {};
    const response = await axios
      .get("/shop-owner/shop-templates/shop/" + id)
      .then((response) => {
        commit("SET_SHOP_TEMPLATE", response.data);
      })
      .catch(function(error) {
        const response = error.response;
        response && response.data
          ? this._vm.$toastr.e(response.data.message)
          : console.log(error);
      });
  },

  async selectShopTemplate({ commit }, formData) {
    return new Promise((resolve, reject) => {
      axios
        .post("/shop-owner/shop-templates", formData)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          error.response ? reject(error.response) : console.log(error);
        });
    });
  },

  async updateShopTemplate({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/shop-owner/shop-templates/" + data.id, data.formData)
        .then((response) => {
          commit("SET_SHOP_TEMPLATE", response.data);

          resolve({ id: response.data.id });
        })
        .catch((error) => {
          error.response ? reject(error.response) : console.log(error);
        });
    });
  },

  async deleteShopTemplate({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/shop-owner/shop-templates/" + id)
        .then((response) => {
          // commit("DELETE_SHOP_TEMPLATE", id);
          resolve(response.data);
        })
        .catch((error) => {
          error.response ? reject(error.response) : console.log(error);
        });
    });
  },
};
const getters = {
  shopTemplate: (state) => state.shopTemplate,
};

const templateModule = {
  state,
  mutations,
  actions,
  getters,
};

export default templateModule;
