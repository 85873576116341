import axios, * as others from "../../../axios";
import * as Vue from "vue";

const state = {
  categories: [],
  category: {},
};
const mutations = {
  SET_CATEGORIES: (state, payload) => (state.categories = payload),
  SET_CATEGORY: (state, payload) => (state.category = payload),
  UPDATE_CATEGORY: (state, category) => {
    //TODO: check if it don't cause issues if the categories are not set
    // like if we go directly to the category show page
    const i = state.categories
      .map((category) => category.id)
      .indexOf(category.id);
    state.categories.splice(i, 1);
    state.categories.splice(i, 0, category);
  },
  DELETE_CATEGORY: (state, id) => {
    const i = state.categories.map((category) => category.id).indexOf(id);
    state.categories.splice(i, 1);
  },
  SET_ADVERT: (state, advert) => (state.category.advert = advert),
  REMOVE_ADVERT: (state) => (state.category.advert = null),
};
const actions = {
  fetchCategories({ commit }, payload) {
    let url = '/shop-owner/'
    if(payload){
      url += 'shops/'+id+'/categories'
    }else{
      url += 'categories'
    }
    axios
      .get(url)
      .then((response) => {
        commit("SET_CATEGORIES", response.data);
      })
      .catch((error) => {
        const response = error.response;
        response && response.data
          ? this._vm.$toastr.e(response.data.message)
          : console.log(error);
      });
  },

  fetchCategory({ commit }, id) {
    state.category = {};
    axios
      .get("/shop-owner/categories/" + id)
      .then((response) => {
        commit("SET_CATEGORY", response.data);
      })
      .catch((error) => {
        const response = error.response;
        response && response.data
          ? this._vm.$toastr.e(response.data.message)
          : console.log(error);
      });
  },

  async storeCategory({ commit }, formData) {
    return new Promise((resolve, reject) => {
      axios
        .post("/shop-owner/categories", formData)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          error.response ? reject(error.response) : console.log(error);
        });
    });
  },

  async updateCategory({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/shop-owner/categories/" + data.id, data.formData)
        .then((response) => {
          commit("UPDATE_CATEGORY", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          error.response ? reject(error.response) : console.log(error);
        });
    });
  },

  async deleteCategory({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/shop-owner/categories/" + id)
        .then((response) => {
          commit("DELETE_CATEGORY", id);
          resolve(response.data);
        })
        .catch((error) => {
          error.response ? reject(error.response) : console.log(error);
        });
    });
  },

  setCategoryAdvert({ commit }, advert) {
    commit("SET_ADVERT", advert);
  },

  removeCategoryAdvert({ commit }) {
    commit("REMOVE_ADVERT");
  },
};
const getters = {
  categories: (state) => state.categories,
  category: (state) => state.category,
};

const categoryModule = {
  state,
  mutations,
  actions,
  getters,
};

export default categoryModule;
