<template>
  <div v-if="tag.id">
    <div class="content-wrapper">
      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0 text-dark">Tag</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item">
                  <router-link :to="'/home'">Home</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link :to="'/list/tag'">Tag List</router-link>
                </li>
                <li class="breadcrumb-item active">{{ tag.name }}</li>
              </ol>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </section>

      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-4">
              <!-- Widget: user widget style 2 -->
              <div class="card card-widget widget-user-2">
                <!-- Add the bg color to the header using any of the bg-* classes -->
                <div class="widget-user-header bg-dark">
                  <!--<div class="widget-user-image">

                                        <img v-if="tag.images[0]" class="img-circle elevation-2" :src="tag.images[0].storageUrl" alt="Tag Image">
                                        <img v-else class="img-circle elevation-2" src="" alt="Tag Image">

                                    </div>-->
                  <!-- /.widget-user-image -->
                  <h3 class="widget-user-username">
                    <i class="fas fa-tag fa-lg"></i> {{ tag.name
                    }}<span class="float-right"
                      ><router-link
                        :to="'/edit/tag/' + tag.id"
                        class="btn btn-sm btn-secondary"
                      >
                        <i class="fas fa-edit"></i></router-link
                      ><button
                        v-on:click="removeTag(tag.id)"
                        class="btn btn-sm btn-outline-danger"
                      >
                        <i class="fas fa-trash"></i></button
                    ></span>
                  </h3>
                </div>
                <div class="card-footer p-0">
                  <ul class="nav flex-column">
                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Tag Id</span>
                        <span class="float-right">{{ tag.id }}</span>
                      </span>
                    </li>

                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Tag Name</span>
                        <span class="float-right">{{ tag.name }}</span>
                      </span>
                    </li>

                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Description:</span>
                        <span>{{ tag.description }}</span>
                      </span>
                    </li>
                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Category:</span>
                        <span>{{ tag.category_name }}</span>
                      </span>
                    </li>

                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Products</span>
                        <span class="float-right badge bg-primary">{{
                          tag.number_of_products
                        }}</span>
                      </span>
                    </li>

                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Date Added</span>
                        <span class="float-right ">{{
                          getDate(tag.created_at)
                        }}</span>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- /.widget-user -->
            </div>

            <div class="col-lg-4 col-6">
              <OrdersWidget></OrdersWidget>
            </div>
          </div>
        </div>
      </section>
      <ProductList v-if="id" :navigation="false" :tag-id="id"></ProductList>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ProductList from "./ListProduct";
import AdvertInfo from "./AdvertInfo";
import OrdersWidget from "./OrdersWidget";

export default {
  name: "TagShow",
  components: {
    ProductList,
    AdvertInfo,
    OrdersWidget,
  },
  computed: {
    ...mapGetters(["shopOwner", "getDate", "tag"]),
  },
  props: {
    id: {
      required: true,
    },
  },
  mounted() {
    this.fetchTag(this.id);
  },
  methods: {
    ...mapActions([
      "fetchTag",
      "deleteTag",
      "showSuccessFlashMessage",
      "showFlashMessage",
    ]),
    async removeTag(id) {
      bootbox.confirm(
        "Are you sure to remove the Tag and all related contents (Associations with products)",
        async (result) => {
          if (result) {
            await this.deleteTag(id)
              .then((res) => {
                this.$toastr.s("Tag Successfully removed");
                this.$router.push("/list/tag").catch((err) => {});
              })
              .catch((err) => {
                this.$toastr.e(err);
              });
          }
        }
      );
    },
  },
};
</script>

<style scoped></style>
