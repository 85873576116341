import axios from "axios";

const state={

}
const mutations={

}
const actions={
    showFlashMessage({commit},message){

        let html = '<div class="alert alert-dismissable alert-danger">\n' +
            '        <button type="button" class="close" data-dismiss="alert" arial-label="close">\n' +
            '            <span aria-hidden="true">&times;</span>\n' +
            '        </button>\n' +
            '        <strong>\n' +
            message +
            '        </strong>\n' +
            '    </div>';

        $('#flash-message').html(html);
        $("#flash-message").fadeTo(10000, 500).slideUp(500, function() {
            $("#flash-message").slideUp(500);
        });
    },
    showSuccessFlashMessage({commit},message){

        let html = '<div class="alert alert-dismissable alert-success">\n' +
            '        <button type="button" class="close" data-dismiss="alert" arial-label="close">\n' +
            '            <span aria-hidden="true">&times;</span>\n' +
            '        </button>\n' +
            '        <strong>\n' +
            message +
            '        </strong>\n' +
            '    </div>';

        $('#flash-message').html(html);
        $("#flash-message").fadeTo(10000, 500).slideUp(500, function() {
            $("#flash-message").slideUp(500);
        });
    }
}
const getters={
    getDate: function getDate(state) {
        return function(date){ return moment.utc(date).add(3, 'hour').format('DD-MM-YYYY, h:mm:ss a');}
    },
    getDateFromNow: function getDate(state) {
        return function(date){ return moment.utc(date).add(3, 'hour').fromNow();}
    },
    displayErrorMessages: function getDate(state) {
        return function(error) {
            let errors = []
          if (error.status == 422) {
            errors = error.data.errors;
          }
          if(error.data && error.data.message){
            this.$toastr.e(error.data.message);
          }else{
              console.log(error)  
              this.$toastr.e('Some thing went wrong!')
          }
         
          return errors;
        };
      },
}

const accessoryModule = {
    state,
    mutations,
    actions,
    getters
}

export default accessoryModule;