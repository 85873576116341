<template>
  <div>
    <!-- Navbar -->
    <nav class="main-header navbar navbar-expand navbar-white navbar-light">
      <!-- Left navbar links -->
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" data-widget="pushmenu" href="#" role="button"
            ><i class="fas fa-bars"></i
          ></a>
        </li>
      </ul>

      <!-- SEARCH FORM -->
      <form class="form-inline ml-3">
        <div class="input-group input-group-sm">
          <input
            class="form-control form-control-navbar"
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
          <div class="input-group-append">
            <button class="btn btn-navbar" type="submit">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </form>

      <div id="flash-message"></div>

      <!-- Right navbar links -->
      <ul class="navbar-nav ml-auto">
        <!-- Notifications Dropdown Menu -->
        <li class="nav-item dropdown">
          <a class="nav-link" data-toggle="dropdown" href="#">
            <i class="far fa-bell"></i>
            <span class="badge badge-warning navbar-badge">0</span>
          </a>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <span class="dropdown-item dropdown-header">0 Notifications</span>
            <div class="dropdown-divider"></div>
            <a href="#" class="dropdown-item">
              <i class="fas fa-envelope mr-2"></i> 0 new messages
              <!-- <span class="float-right text-muted text-sm">3 mins</span> -->
            </a>
            <!-- <div class="dropdown-divider"></div>
                        <a href="#" class="dropdown-item">
                            <i class="fas fa-users mr-2"></i> 0 friend requests
                            <span class="float-right text-muted text-sm">12 hours</span>
                        </a> -->
            <div class="dropdown-divider"></div>
            <a href="#" class="dropdown-item">
              <i class="fas fa-file mr-2"></i> 0 new reports
              <!-- <span class="float-right text-muted text-sm">2 days</span> -->
            </a>
            <div class="dropdown-divider"></div>
            <a href="#" class="dropdown-item dropdown-footer"
              >See All Notifications</a
            >
          </div>
        </li>
        <li class="nav-item">
          <a href="#" v-on:click.prevent="logout" class="nav-link"
            ><i class="fas fa-sign-out-alt"></i>Logout</a
          >
        </li>
      </ul>
    </nav>
    <!-- /.navbar -->

    <!-- Main Sidebar Container -->
    <!-- <overlay-scrollbars> -->
    <aside class="main-sidebar sidebar-dark-primary elevation-5">
      <!-- Brand Logo -->
      <router-link :to="'/home'" class="brand-link">
        <img
          src="/images/logo-w.png"
          alt="Batelew Logo"
          class="brand-image img-circle elevation-3"
          style="opacity: 0.8"
        />
        <span class="brand-text font-weight-light">Batelew&#8482;</span>
      </router-link>

      <!-- Sidebar -->
      <div class="sidebar">
        <!-- Sidebar Menu -->
        <nav class="mt-2">
          <ul
            class="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <!-- Add icons to the links using the .nav-icon class
                             with font-awesome or any other icon font library -->
            <li class="nav-item has-treeview">
              <a class="nav-link">
                <i class="nav-icon fas fa-chart-pie"></i>
                <p>
                  Statistics
                  <i class="fas fa-angle-left right"></i>
                </p>
              </a>
              <ul class="nav nav-treeview">
                <li class="nav-item">
                  <router-link :to="'/stats'">
                    <a class="nav-link">
                      <i class="fas fa-shopping-cart nav-icon"></i>
                      <p>General</p>
                    </a>
                  </router-link>
                </li>
              </ul>
            </li>

            <div class="nav-divider">
              <hr />
            </div>

            <li class="nav-item">
              <router-link :to="'/list/category'" class="nav-link">
                <i class="nav-icon fas fa-shopping-basket"></i>
                <p>
                  Categories
                </p>
              </router-link>
            </li>

            <li class="nav-item">
              <router-link :to="'/list/tag'" class="nav-link">
                <i class="nav-icon fas fa-tag"></i>
                <p>
                  Tags
                </p>
              </router-link>
            </li>

            <li class="nav-item">
              <router-link :to="'/list/service'" class="nav-link">
                <i class="nav-icon fas fa-cogs"></i>
                <p>
                  Services
                </p>
              </router-link>
            </li>

            <li v-if="shopOwner.has_a_shop" class="nav-item">
              <router-link :to="'/show/shop'" class="nav-link">
                <i class="nav-icon fas fa-address-card"></i>
                <p>
                  View Shop
                </p>
              </router-link>
            </li>

            <li v-else class="nav-item">
              <router-link :to="'/add/shop'" class="nav-link">
                <i class="nav-icon fas fa-address-card"></i>
                <p>
                  Add a Shop
                </p>
              </router-link>
            </li>

            <li class="nav-item">
              <router-link :to="'/list/template'" class="nav-link">
                <i class="nav-icon fas fa-store"></i>
                <p>
                  Shop Templates
                </p>
              </router-link>
            </li>

            <li class="nav-item">
              <router-link :to="'/list/order'" class="nav-link">
                <i class="fas fa-list-alt nav-icon"></i>
                <p>
                  Orders
                </p>
              </router-link>
            </li>
          </ul>
        </nav>
        <!-- /.sidebar-menu -->
      </div>
      <!-- /.sidebar -->
    </aside>
    <!-- </overlay-scrollbars> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "shopOwnerLayout",
  props: [],
  data() {
    return {
      menuList: [],
    };
  },
  computed: {
    ...mapGetters(["shop", "shopOwner"]),
  },
  mounted() {
    
  },
  methods: {
    ...mapActions(["logoutShopOwner", "fetchShop","fetchShopOwner"]),
    search() {},  
    logout() {
      this.logoutShopOwner().then(() => {
        location.reload();
      });
    },
  },
};
</script>
