<template>
    <AddressForm :edit="true"></AddressForm>
</template>

<script>
    import AddressForm from './AddressForm'
    export default {
        name: "EditAddress",
        components: {
            AddressForm
        }
    }
</script>

<style scoped>

</style>