<template>
  <div v-if="shopOwner.id">
    <div
      v-if="!shopOwner.shop || edit"
      class="content-wrapper"
      style="min-height: 1246.8px;"
    >
      <!-- Content Header (Page header) -->
      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 v-if="edit">Edit Shop</h1>
              <h1 v-else>Add Shop</h1>
            </div>
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item">
                  <router-link :to="'/home'">Home</router-link>
                </li>
                <li class="breadcrumb-item active">Add Shop</li>
              </ol>
            </div>
          </div>
        </div>
        <!-- /.container-fluid -->
      </section>

      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <!-- left column -->
            <div class="col-md-6">
              <!-- general form elements -->
              <div class="card card-primary">
                <div class="card-header bg-info">
                  <h3 class="card-title">Shop Form</h3>
                </div>
                <!-- /.card-header -->
                <!-- form start -->
                <form v-on:submit.prevent="submitForm" role="form">
                  <div class="card-body">
                    <div class="form-group">
                      <label for="exampleInputName">Shop Name</label>
                      <input
                        required
                        v-model="name"
                        type="text"
                        class="form-control"
                        :class="errors.shop_name ? 'is-invalid' : ''"
                        id="exampleInputName"
                        placeholder="Enter shop name"
                      />
                      <div class="invalid-feedback">
                        <span v-if="errors.shop_name && errors.shop_name[0]">{{
                          errors.shop_name[0]
                        }}</span>
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="exampleInputName">Shop Url</label>
                      <input
                        required
                        v-model="url"
                        type="text"
                        class="form-control"
                        :class="errors.shop_url ? 'is-invalid' : ''"
                        id="exampleInputUrl"
                        placeholder="Enter shop url (no space)"
                      />
                      <div class="invalid-feedback">
                        <span v-if="errors.shop_url && errors.shop_url[0]">{{
                          errors.shop_url[0]
                        }}</span>
                      </div>
                    </div>

                    <div class="form-group">
                      <label>Description</label>
                      <textarea
                        v-model="description"
                        class="form-control"
                        :class="errors.description ? 'is-invalid' : ''"
                        rows="3"
                        placeholder="Enter Description ..."
                      ></textarea>
                      <div class="invalid-feedback">
                        <span
                          v-if="errors.description && errors.description[0]"
                          >{{ errors.description[0] }}</span
                        >
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="input-email">Email</label>
                      <input
                        required
                        v-model="email"
                        type="email"
                        class="form-control"
                        :class="errors.email ? 'is-invalid' : ''"
                        id="input-email"
                        placeholder="Enter Email"
                      />
                      <div class="invalid-feedback">
                        <span v-if="errors.email && errors.email[0]">{{
                          errors.email[0]
                        }}</span>
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="input-phone">Phone Number</label>
                      <input
                        required
                        v-model="phone"
                        type="text"
                        class="form-control"
                        :class="errors.phone ? 'is-invalid' : ''"
                        id="input-phone"
                        placeholder="Enter cell number"
                      />
                       <div class="invalid-feedback">
                        <span v-if="errors.phone && errors.phone[0]">{{
                          errors.phone[0]
                        }}</span>
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="image-file">
                        <slot v-if="shop.id">
                          <span v-if="shop.images.length > 0">Replace</span>
                          <span v-else>Add</span>
                        </slot>
                        <span v-else>Add</span>
                        Logo
                      </label>
                      <div class="input-group">
                        <div class="custom-file">
                          <input
                            v-on:change="fileChange"
                            type="file"
                            class="custom-file-input"
                            id="image-file"
                          />
                          <label
                            id="image-label"
                            class="custom-file-label"
                            for="image-file"
                            >Choose file</label
                          >
                        </div>
                        <div class="input-group-append">
                          <span class="input-group-text" id="">Upload</span>
                        </div>
                      </div>
                      <slot v-if="edit">
                        <div class="form-check" v-if="shop.images.length > 0">
                          <label class="form-check-label" for="checkReplace"
                            ><input
                              v-model="replaceImages"
                              type="checkbox"
                              checked
                              class="form-check-input"
                              id="checkReplace"
                            />Remove Logo</label
                          >
                        </div>
                      </slot>
                    </div>
                  </div>
                  <!-- /.card-body -->

                  <div class="card-footer">
                    <div v-if="working" class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                    <br />
                    <button
                      :disabled="working"
                      type="submit"
                      id="submit-btn"
                      class="btn bg-info"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
              <!-- /.card -->
            </div>
            <!--/.col (left) -->
            <!-- right column -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </section>
      <!-- /.content -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ShopForm",
  data() {
    return {
      name: "",
      url: "",
      description: "",
      email: "",
      phone: "",
      replaceImages: false,
      working: false,
      errors: [],
    };
  },
  props: {
    edit: {
      default: false,
    },
  },
  watch: {
    shop() {
      if (this.shop.id) {
        this.setData();
      } else {
        this.resetData();
      }
    },
  },
  computed: {
    ...mapGetters(["shop","shopOwner","displayErrorMessages"]),
  },

  mounted() {
    this.setData();
  },

  methods: {
    ...mapActions([
      "fetchShop",
      "storeShop",
      "updateShop",
      "showFlashMessage",
      "showSuccessFlashMessage",
    ]),
    async submitForm() {
      if (!this.working) {
        this.working = true;

        let formData = new FormData();

        formData.append("description", this.description);
        formData.append("email", this.email);
        formData.append("shop_name", this.name);
        formData.append("shop_url", this.url);
        formData.append("phone_number", this.phone);

        if (document.getElementById("image-file")) {
          for (
            let i = 0;
            i < document.getElementById("image-file").files.length;
            i++
          ) {
            formData.append(
              "images[]",
              document.getElementById("image-file").files[i]
            );
          }
        }

        if (this.edit) {
          formData.append("_method", "PUT");
          formData.append("replaceImages", this.replaceImages);
          await this.updateShop({ formData: formData, id: this.shop.id })
            .then((response) => {
              this.$toastr.s("Shop Updated Successfully");
              this.$router.push("/show/shop/").catch((e) => {
                console.log(e);
              });
            })
            .catch((error) => {
              this.errors = this.displayErrorMessages(error);
            });
        } else {
          await this.storeShop(formData)
            .then((response) => {
              this.$toastr.s("Shop Added Successfully");
              this.$router.push("/show/shop/").catch((e) => {
                console.log(e);
              });
            })
            .catch((error) => {
              this.errors = this.displayErrorMessages(error);
            });
        }

        this.working = false;
      }
    },

    fileChange() {
      let files = document.getElementById("image-file").files;

      document.getElementById("image-label").innerHTML = "";

      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          document.getElementById("image-label").innerHTML =
            document.getElementById("image-label").innerHTML + files[i].name;
          document.getElementById("image-label").innerHTML =
            document.getElementById("image-label").innerHTML + ", ";
        }
      } else {
        document.getElementById("image-label").innerHTML = "Choose file";
      }
    },

    setData() {
      if (this.shop.id) {
        this.name = this.shop.shop_name;
        this.url = this.shop.shop_url;
        this.description = this.shop.description;
        this.email = this.shop.email;
        this.phone = this.shop.phone_number;
      }
    },

    resetData() {
      this.name = "";
      this.url = "";
      this.description = "";
      this.email = "";
      this.phone = "";
      this.removeImages = false;
    },
  },
};
</script>

<style scoped></style>
