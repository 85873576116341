<template>
  <div class="content-wrapper" style="min-height: 1246.8px;">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 v-if="id">Edit Tag</h1>
            <h1 v-else>Add Tag</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="'/home'">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Add Tag</li>
            </ol>
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <!-- left column -->
          <div class="col-md-6">
            <!-- general form elements -->
            <div class="card card-primary">
              <div class="card-header bg-info">
                <h3 class="card-title">Tag Form</h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <form v-on:submit.prevent="submitForm" role="form">
                <div class="card-body">
                  <div class="form-group">
                    <label for="exampleInputName">Name</label>
                    <input
                      required
                      v-model="name"
                      type="text"
                      class="form-control"
                      :class="errors.name ? 'is-invalid' : ''"
                      id="exampleInputName"
                      placeholder="Enter name"
                    />
                    <div class="invalid-feedback">
                      <span v-if="errors.name && errors.name[0]">{{
                        errors.name[0]
                      }}</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Description</label>
                    <textarea
                      v-model="description"
                      class="form-control"
                      :class="errors.description ? 'is-invalid' : ''"
                      rows="3"
                      placeholder="Enter Description ..."
                    ></textarea>
                    <div class="invalid-feedback">
                      <span
                        v-if="errors.description && errors.description[0]"
                        >{{ errors.description[0] }}</span
                      >
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="typo__label">Category</label>
                    <multi-select
                      v-model="category"
                      track-by="name"
                      label="name"
                      placeholder="Select one"
                      :options="categoriesForSelect"
                      :searchable="true"
                      :allow-empty="true"
                    >
                      <template
                        slot="singleLabel"
                        slot-scope="{ option }"
                      ></template>
                    </multi-select>
                  </div>
                </div>
                <!-- /.card-body -->

                <div class="card-footer">
                  <div v-if="working" class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <br />
                  <button
                    :disabled="working"
                    type="submit"
                    id="submit-btn"
                    class="btn bg-info"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
            <!-- /.card -->
          </div>
          <!--/.col (left) -->
          <!-- right column -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AddTag",
  data() {
    return {
      name: "",
      description: "",
      category: {},
      working: false,
      errors: [],
    };
  },
  props: {
    id: {
      default: null,
    },
  },
  watch: {
    tag() {
      if (this.tag.id) {
        this.setData();
      } else {
        this.resetData();
      }
    },
  },
  computed: {
    ...mapGetters(["tag", "categories", "displayErrorMessages"]),
    categoriesForSelect() {
      let categoriesForSelect = [];
      if (this.categories.length > 0) {
        this.categories.forEach((category) => {
          categoriesForSelect.push({ name: category.name, id: category.id });
        });
      }
      return categoriesForSelect;
    },
  },

  mounted() {
    this.setData();
    if (this.id) {
      this.fetchTag(this.id);
    }
  },
  methods: {
    ...mapActions([
      "fetchTag",
      "storeTag",
      "updateTag",
      "showFlashMessage",
      "showSuccessFlashMessage",
    ]),
    async submitForm() {
      if (!this.working) {
        this.working = true;

        let formData = new FormData();

        formData.append("name", this.name);
        formData.append("description", this.description);
        if (this.category) {
          if (this.category.id) {
            formData.append("category_id", this.category.id);
          }
        }

        if (this.id) {
          formData.append("_method", "PUT");
          await this.updateTag({ formData: formData, id: this.id })
            .then((response) => {
              this.$toastr.s("Tag Updated Successfully");
              this.$router.push("/show/tag/" + response.id).catch((e) => {
                console.log(e);
              });
            })
            .catch((error) => {
              this.errors = this.displayErrorMessages(error);
            });
        } else {
          await this.storeTag(formData)
            .then((response) => {
              this.$toastr.s("Tag Added Successfully");
              this.$router.push("/show/tag/" + response.id).catch((e) => {
                console.log(e);
              });
            })
            .catch((error) => {
              this.errors = this.displayErrorMessages(error);
            });
        }

        this.working = false;
      }
    },

    fileChange() {
      let files = document.getElementById("image-file").files;

      document.getElementById("image-label").innerHTML = "";

      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          document.getElementById("image-label").innerHTML =
            document.getElementById("image-label").innerHTML + files[i].name;
          document.getElementById("image-label").innerHTML =
            document.getElementById("image-label").innerHTML + ", ";
        }
      } else {
        document.getElementById("image-label").innerHTML = "Choose file";
      }
    },

    setData() {
      if (this.id && this.tag.id) {
        this.name = this.tag.name;
        this.description = this.tag.description;
        this.category = {
          name: this.tag.category.name,
          id: this.tag.category.id,
        };
      }
    },

    resetData() {
      this.name = "";
      this.description = "";
      this.category = {};
    },
  },
};
</script>

<style scoped></style>
