<template>
  <div v-if="category.id">
    <div class="content-wrapper">
      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0 text-dark">Category</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item">
                  <router-link :to="'/home'">Home</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link :to="'/list/category'"
                    >Category List</router-link
                  >
                </li>
                <li class="breadcrumb-item active">{{ category.name }}</li>
              </ol>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </section>

      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-4">
              <!-- Widget: user widget style 2 -->
              <div class="card card-widget widget-user-2">
                <!-- Add the bg color to the header using any of the bg-* classes -->
                <div class="widget-user-header bg-dark">
                  <div class="widget-user-image">
                    <img
                      v-if="category.images[0]"
                      class="img-circle elevation-2"
                      :src="serverUrl + category.images[0].thumbUrl"
                      alt="Category Image"
                    />
                    <img
                      v-else
                      class="img-circle elevation-2"
                      src=""
                      alt="Category Image"
                    />
                  </div>
                  <!-- /.widget-user-image -->
                  <h3 class="widget-user-username">
                    {{ category.name }}
                    <span class="float-right">
                      <router-link
                        :to="'/edit/category/' + category.id"
                        class="btn btn-sm btn-secondary"
                      >
                        <i class="fas fa-edit"></i
                      ></router-link>
                      <button
                        v-on:click="removeCategory(category.id)"
                        class="btn btn-sm btn-outline-danger"
                      >
                        <i class="fas fa-trash"></i>
                      </button>
                    </span>
                  </h3>
                </div>
                <div class="card-footer p-0">
                  <ul class="nav flex-column">
                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Category ID</span>
                        <span class="float-right">{{ category.id }}</span>
                      </span>
                    </li>

                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Category Name</span>
                        <span class="float-right">{{ category.name }}</span>
                      </span>
                    </li>

                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Description:</span>
                        <span>{{ category.description }}</span>
                      </span>
                    </li>

                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Products</span>
                        <span class="float-right badge bg-primary">{{
                          category.number_of_products
                        }}</span>
                      </span>
                    </li>
                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Tags</span>
                        <span class="float-right badge bg-primary">{{
                          category.number_of_tags
                        }}</span>
                      </span>
                    </li>
                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Has Quantity</span>
                        <span class="float-right ">{{
                          category.has_quantity ? "yes" : "no"
                        }}</span>
                      </span>
                    </li>
                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Date Added</span>
                        <span class="float-right ">{{
                          getDate(category.created_at)
                        }}</span>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- /.widget-user -->
            </div>

            <AdvertInfo
              :type="'category'"
              :shop-owner="true"
              :advertable="category"
              :selected-advert="category.advert"
              @set-advert="setCategoryAdvert"
              @remove-advert="removeCategoryAdvert"
            ></AdvertInfo>

            <div class="col-lg-4 col-6">
              <!-- small box -->
              <OrdersWidget></OrdersWidget>
            </div>
          </div>
        </div>
      </section>
      <ProductList
        v-if="id"
        :add="true"
        :navigation="false"
        :category-id="id"
      ></ProductList>
      <TagList
        v-if="id"
        :show-category="false"
        :navigation="false"
        :category-id="id"
      ></TagList>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ProductList from "./ListProduct";
import TagList from "./ListTag";
import AdvertInfo from "./AdvertInfo";
import OrdersWidget from "./OrdersWidget";

export default {
  name: "CategoryShow",
  components: {
    TagList,
    ProductList,
    AdvertInfo,
    OrdersWidget,
  },
  computed: {
    ...mapGetters(["getDate", "serverUrl", "category"]),
  },
  props: {
    id: {
      required: true,
    },
  },
  async mounted() {
    if (this.id) {
      await this.fetchCategory(this.id);
    }
  },
  methods: {
    ...mapActions([
      "fetchCategory",
      "deleteCategory",
      "setCategoryAdvert",
      "removeCategoryAdvert",
      "showSuccessFlashMessage",
      "showFlashMessage",
    ]),
    async removeCategory(id) {
      bootbox.confirm(
        "Are you sure to delete the category and all its related data (products, tags)?",
        async (result) => {
          if (result) {
            await this.deleteCategory(id)
              .then((res) => {
                this.$toastr.s("Category removed successfully.");
                this.$router.push("/list/category").catch((err) => {});
              })
              .catch((err) => {
                this.$toastr.e(err);
              });
          }
        }
      );
    },
  },
};
</script>

<style scoped></style>
