<template>
  <div
    class="content-wrapper"
    style="min-height: 1246.8px;"
    v-if="(categoryId && selectedCategory.id) || !categoryId"
  >
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 v-if="id">Edit Product</h1>
            <h1 v-else>
              Add a Product
              <slot v-if="categoryId"
                ><span v-if="selectedCategory"
                  >for {{ selectedCategory.name }}</span
                ></slot
              >
            </h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="'/home'">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Product Form</li>
            </ol>
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <!-- left column -->
          <div class="col-md-6">
            <!-- general form elements -->
            <div class="card card-primary">
              <div class="card-header bg-info">
                <h3 class="card-title">Product Form</h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <form v-on:submit.prevent="submitForm" role="form">
                <div class="card-body">
                  <div class="form-group">
                    <label for="exampleInputName">Name</label>
                    <input
                      required
                      v-model="name"
                      type="text"
                      class="form-control"
                      :class="errors.name ? 'is-invalid' : ''"
                      id="exampleInputName"
                      placeholder="Enter name"
                    />
                    <div class="invalid-feedback">
                      <span v-if="errors.name && errors.name[0]">{{
                        errors.name[0]
                      }}</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Description</label>
                    <textarea
                      v-model="description"
                      class="form-control"
                      :class="errors.description ? 'is-invalid' : ''"
                      rows="3"
                      placeholder="Enter Description ..."
                    ></textarea>
                    <div class="invalid-feedback">
                      <span
                        v-if="errors.description && errors.description[0]"
                        >{{ errors.description[0] }}</span
                      >
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="exampleInputPrice">Price</label>
                    <div class="input-group">
                      <input
                        required
                        v-model="price"
                        type="text"
                        class="form-control"
                        :class="errors.price ? 'is-invalid' : ''"
                        id="exampleInputPrice"
                        placeholder="Enter product price"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text">ETB</span>
                      </div>
                      <div class="invalid-feedback">
                        <span v-if="errors.price && errors.price[0]">{{
                          errors.price[0]
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="exampleInputDiscount">Discount</label>
                    <div class="input-group">
                      <input
                        required
                        v-model="discount"
                        type="text"
                        class="form-control"
                        :class="errors.discount ? 'is-invalid' : ''"
                        id="exampleInputDiscount"
                        placeholder="Enter product discount"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text">ETB</span>
                      </div>
                      <div class="invalid-feedback">
                        <span v-if="errors.discount && errors.discount[0]">{{
                          errors.discount[0]
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <slot v-if="selectedCategory.id">
                    <div
                      class="form-group"
                      v-if="selectedCategory.has_quantity"
                    >
                      <label for="input-quantity">Quantity</label>
                      <input
                        required
                        v-model="quantity"
                        type="text"
                        class="form-control"
                        :class="errors.quantity ? 'is-invalid' : ''"
                        id="input-quantity"
                        placeholder="Enter quantity"
                      />
                      <div class="invalid-feedback">
                        <span v-if="errors.quantity && errors.quantity[0]">{{
                          errors.quantity[0]
                        }}</span>
                      </div>
                    </div>
                  </slot>

                  <div class="form-group" v-if="!categoryId">
                    <label>Select Category</label>
                    <select
                      class="form-control"
                      :class="errors.category_id ? 'is-invalid' : ''"
                      v-model="selectedCategoryId"
                    >
                      <option value="" selected>-</option>
                      <option
                        v-for="category in categories"
                        v-bind:key="category.id"
                        :value="category.id"
                        >{{ category.name }}</option
                      >
                    </select>
                    <div class="invalid-feedback">
                      <span
                        v-if="errors.category_id && errors.category_id[0]"
                        >{{ errors.category_id[0] }}</span
                      >
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Tags</label>
                    <multi-select
                      v-model="selectedMultiTags"
                      :options="tags"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      placeholder="Pick some"
                      label="name"
                      track-by="name"
                      :preselect-first="false"
                    >
                      <template
                        slot="selection"
                        slot-scope="{ values, search, isOpen }"
                        ><span
                          class="multiselect__single"
                          v-if="values.length &amp;&amp; !isOpen"
                          >{{ values.length }} options selected</span
                        ></template
                      >
                    </multi-select>
                    <div v-if="errors.tags && errors.tags[0]">
                      <span class="text-danger">{{ errors.tags[0] }}</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Properties</label>
                    <div
                      class="custom-control custom-checkbox"
                      v-for="(property, index) in properties"
                      v-bind:key="index"
                    >
                      <input
                        class="custom-control-input"
                        type="checkbox"
                        :id="'customCheckbox' + index"
                        :value="property.name"
                        v-model="selectedProperties"
                      />
                      <label
                        :for="'customCheckbox' + index"
                        class="custom-control-label"
                        >{{ property.name }}</label
                      >
                    </div>
                  </div>

                  <slot v-for="property in selectedProperties">
                    <slot v-if="property === 'size'">
                      <div class="form-group">
                        <label>Select Sizes</label>
                        <multi-select
                          v-model="selectedMultiSizes"
                          :options="multiSizesOption"
                          :multiple="true"
                          :close-on-select="false"
                          :clear-on-select="false"
                          :preserve-search="true"
                          placeholder="Pick some"
                          label="name"
                          track-by="name"
                          :preselect-first="false"
                        >
                          <template
                            slot="selection"
                            slot-scope="{ values, search, isOpen }"
                            ><span
                              class="multiselect__single"
                              v-if="values.length &amp;&amp; !isOpen"
                              >{{ values.length }} options selected</span
                            ></template
                          >
                        </multi-select>
                        <div v-if="errors.sizes && errors.sizes[0]">
                          <span class="text-danger">{{ errors.sizes[0] }}</span>
                        </div>
                      </div>
                    </slot>
                    <slot v-if="property === 'area'">
                      <div class="form-group">
                        <label>Area</label>
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <input
                              type="text"
                              class="form-control"
                              :class="errors.area_width ? 'is-invalid' : ''"
                              placeholder="Width"
                              v-model="area.width"
                            />
                            <div class="invalid-feedback">
                              <span
                                v-if="errors.area_width && errors.area_width[0]"
                                >{{ errors.area_width[0] }}</span
                              >
                            </div>
                          </div>

                          <input
                            type="text"
                            class="form-control"
                            :class="errors.area_height ? 'is-invalid' : ''"
                            placeholder="Height"
                            v-model="area.height"
                          />
                          <div class="invalid-feedback">
                            <span
                              v-if="errors.area_height && errors.area_height[0]"
                              >{{ errors.area_height[0] }}</span
                            >
                          </div>

                          <div class="input-group-append">
                            <multi-select
                              v-model="area.measurement"
                              :options="getProperty('area').data"
                              :searchable="false"
                              :close-on-select="false"
                              :show-labels="false"
                              placeholder="Metric"
                            ></multi-select>
                            <div
                              v-if="
                                errors.area_measurement &&
                                  errors.area_measurement[0]
                              "
                            >
                              <span class="text-danger">{{
                                errors.area_measurement[0]
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </slot>
                    <slot v-if="property === 'color'">
                      <div>
                        <span
                          v-for="color in selectedColors"
                          v-bind:key="color.id"
                          :style="'background-color:' + color + ';'"
                          class="email-ids"
                        >
                          <span
                            @click="removeSelectedColor(color)"
                            class="cancel-email"
                            >x</span
                          >
                        </span>
                      </div>
                      <br /><br />
                      <div>
                        <label for="color-pick">Pick Colors</label>
                        <input
                          id="color-pick"
                          type="color"
                          v-model="selectedColor"
                          @change="selectColor()"
                        />
                        <div v-if="errors.colors && errors.colors[0]">
                          <span class="text-danger">{{
                            errors.colors[0]
                          }}</span>
                        </div>
                      </div>
                    </slot>
                  </slot>

                  <div class="form-group" v-if="id">
                    <label>Category sold out</label>
                    <div class="form-check">
                      <label class="form-check-label" for="exampleCheck1"
                        >Sold out</label
                      >
                      <input
                        v-model="soldOut"
                        type="checkbox"
                        checked
                        class="form-check-input"
                        :class="errors.sold_out ? 'is-invalid' : ''"
                        id="exampleCheck1"
                      />

                      <div class="invalid-feedback">
                        <span v-if="errors.sold_out && errors.sold_out[0]">{{
                          errors.sold_out[0]
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="image-file">Add Images</label>
                    <div class="input-group">
                      <div class="custom-file">
                        <input
                          v-on:change="fileChange"
                          type="file"
                          name="file[]"
                          
                          multiple
                          class="custom-file-input"
                          :class="errors.image ? 'is-invalid' : ''"
                          id="image-file"
                        />
                        <label
                          id="image-label"
                          class="custom-file-label"
                          for="image-file"
                          >Choose file</label
                        >
                        br
                         
                      </div>
                      
                      <div class="input-group-append">
                        <span class="input-group-text" id="">Upload</span>
                      </div>
                      
                    </div>
                    <div  v-if="errors.image && errors.image[0]">
                        <span class="text-danger">{{
                          errors.image[0]
                        }}</span>
                      </div>
                  </div>

                  <div class="form-group" v-if="id">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        :value="0"
                        type="radio"
                        name="radio1"
                        checked=""
                        v-model="replaceImages"
                      />
                      <label class="form-check-label">Add More Images</label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        :value="1"
                        type="radio"
                        name="radio1"
                        v-model="replaceImages"
                      />
                      <label class="form-check-label"
                        >Replace/Remove Images</label
                      >
                    </div>
                  </div>
                </div>

                <!-- /.card-body -->

                <div class="card-footer">
                  <div v-if="working" class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <br />
                  <button
                    :disabled="working"
                    type="submit"
                    id="submit-btn"
                    class="btn bg-info"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
            <!-- /.card -->
          </div>
          <!--/.col (left) -->
          <!-- right column -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ProductForm",
  data() {
    return {
      name: "",
      description: "",
      price: 0,
      discount: 0,
      selectedCategoryId: null,
      replaceImages: 0,
      selectedTags: [],
      quantity: 1,
      selectedProperties: [],
      selectedSizes: [],
      selectedColors: [],
      area: {
        width: "",
        height: "",
        measurement: "",
      },
      selectedColor: "#fdffff",
      soldOut: false,
      working: false,
      selectedMultiTags: [],
      selectedMultiSizes: [],
      errors: [],
    };
  },
  props: {
    id: {
      default: null,
    },
    categoryId: {
      default: null,
    },
    tagId: {
      default: null,
    },
  },
  watch: {
    product() {
      if (this.product.id) {
        this.setData();
      } else {
        this.resetData();
      }
    },
  },
  computed: {
    ...mapGetters([
      "product",
      "category",
      "tags",
      "categories",
      "properties",
      "displayErrorMessages",
    ]),

    selectedCategory() {
      if (this.selectedCategoryId) {
        return this.category
      } else {
        return {};
      }
    },
    multiSizesOption() {
      
        let sizes = this.getProperty("size").data;
        let updSizes = [];
        if (sizes) {
          sizes.forEach((size) => {
            updSizes.push({ name: size });
          });
        }

        return updSizes;
      
    },
  },

  mounted() {
    this.setData();
    if (this.id) {
        this.fetchProduct(this.id);
      }
      if (this.selectedCategoryId) {
        this.fetchCategory(this.selectedCategoryId)
        this.fetchCategoryTags(this.selectedCategoryId);
      }
    
    this.fetchProperties();
  },
  methods: {
    ...mapActions([
      "fetchProduct",
      "fetchCategory",
      "fetchCategoryTags",
      "storeProduct",
      "updateProduct",
      "showFlashMessage",
      "showSuccessFlashMessage",
      "fetchProperties",
    ]),
    async submitForm() {
      if (!this.working) {
        this.working = true;

        let formData = new FormData();
        let pass = true;

        formData.append("name", this.name);
        formData.append("description", this.description);
        formData.append("price", this.price);
        formData.append("discount", this.discount);
        formData.append("category_id", this.selectedCategoryId);
        formData.append("quantity", this.quantity);

        if (document.getElementById("image-file")) {
          for (
            let i = 0;
            i < document.getElementById("image-file").files.length;
            i++
          ) {
            formData.append(
              "images[]",
              document.getElementById("image-file").files[i]
            );
          }
        }

        for (let i = 0; i < this.selectedMultiTags.length; i++) {
          formData.append("tags[]", this.selectedMultiTags[i].id);
        }
        if (this.tagId) {
          formData.append("tags[]", this.tagId);
        }

        if (this.selectedProperties.length > 0) {
          this.selectedProperties.forEach((property) => {
            if (property === "color") {
              for (let i = 0; i < this.selectedColors.length; i++) {
                formData.append("colors[]", this.selectedColors[i]);
              }
            }

            if (property === "size") {
              for (let i = 0; i < this.selectedMultiSizes.length; i++) {
                formData.append("sizes[]", this.selectedMultiSizes[i].name);
              }
            }

            if (property === "area") {
              if (
                this.area.height &&
                this.area.width &&
                this.area.measurement
              ) {
                formData.append("area_height", this.area.height);
                formData.append("area_width", this.area.width);
                formData.append("area_measurement", this.area.measurement);
              } else {
                this.$toastr.e("All area properties must be provided!");
                pass = false;
              }
            }
          });
        }
        if (pass) {
          if (this.id) {
            formData.append("replaceImages", this.replaceImages);
            formData.append("_method", "PUT");
            let soldOut = 0;
            if (this.soldOut === true) {
              soldOut = 1;
            }
            formData.append("soldOut", soldOut);

            await this.updateProduct({ formData: formData, id: this.id })
              .then((response) => {
                this.$toastr.s("Product Updated Successfully");
                this.$router.push("/show/product/" + response.id).catch((e) => {
                  console.log(e);
                });
              })
              .catch((error) => {
                this.errors = this.displayErrorMessages(error);
              });
          } else {
            await this.storeProduct(formData)
              .then((response) => {
                this.$toastr.s("Product Added Successfully");
                this.$router.push("/show/product/" + response.id).catch((e) => {
                  console.log(e);
                });
              })
              .catch((error) => {
                this.errors = this.displayErrorMessages(error);
              });
          }
        }

        this.working = false;
      }
    },

    fileChange() {
      let files = document.getElementById("image-file").files;

      document.getElementById("image-label").innerHTML = "";

      if (files.length > 0) {
        if (files.length > 1) {
          document.getElementById("image-label").innerHTML =
            files.length + " files selected";
        } else {
          for (let i = 0; i < files.length; i++) {
            document.getElementById("image-label").innerHTML =
              document.getElementById("image-label").innerHTML + files[i].name;
            document.getElementById("image-label").innerHTML =
              document.getElementById("image-label").innerHTML + ", ";
          }
        }
      } else {
        document.getElementById("image-label").innerHTML = "Choose file";
      }
    },

    setData() {
      if (this.id && this.product.id) {
        this.name = this.product.name;
        this.description = this.product.description;
        this.price = this.product.price;
        this.discount = this.product.discount;
        this.quantity = this.product.quantity;
        this.selectedCategoryId = this.product.category_id;
        let tags = [];
        this.product.tags.forEach((tag) => {
          tags.push({ name: tag.name, id: tag.id });
        });
        this.selectedMultiTags = tags;
        
        this.selectedProperties = this.product.properties.properties;
        this.product.properties.sizes.forEach((size) => {
          this.selectedMultiSizes.push({ name: size });
        });
        this.selectedColors = this.product.properties.colors;
        this.area = this.product.properties.area;
        this.soldOut = this.product.sold_out;
      } else {
        if (this.categoryId) {
          this.selectedCategoryId = this.categoryId;
        }
      }
    },

    resetData() {
      this.name = "";
      this.description = "";
      this.price = 0;
      this.discount = 0;
      this.selectedCategoryId = null;
      this.selectedProperties = [];
      this.selectedMultiTags = [];
      this.selectedMultiSizes = [];
      this.selectedSizes = [];
      this.selectedColors = [];
      this.area = {
        width: "",
        height: "",
        measurement: "",
      };
      this.quantity = 0;
      this.replaceImages = 0;
      this.area = {
        width: "",
        height: "",
        measurement: "",
      };
      this.soldOut = false;
    },

    getProperty(name) {
      let property = {};

      this.properties.forEach((prop) => {
        if (prop.name == name) {
          property = prop;
        }
      });

      return property;
    },

    selectColor() {
      let color = this.selectedColor;
      let pass = true;
      for (let i = 0; i < this.selectedColors.length; i++) {
        if (this.selectedColors[i] === color) {
          pass = false;
          return;
        }
      }
      if (pass) {
        this.selectedColors.push(color);
      }

      this.selectedColor = "#fdffff";
    },
    removeSelectedColor(color) {
      this.selectedColors = this.selectedColors.filter(function(obj) {
        return obj !== color;
      });
    },    
  },
};
</script>

<style scoped></style>
