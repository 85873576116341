<template>
    <div>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Contact List <span class="badge badge-info right">{{filteredContacts.length}}</span><router-link :to="'/add/contact'" class="badge badge-info right"><i class="fas fa-plus"></i></router-link></h3>

                                <div class="card-tools">
                                    <div class="input-group input-group-sm" style="width: 150px;">
                                        <input type="text" v-model="searchString" name="table_search" class="form-control float-right" placeholder="Search">

                                        <div class="input-group-append">
                                            <button type="submit" class="btn btn-default"><i class="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- /.card-header -->

                            <div class="card-body table-responsive p-0">
                                <table class="table table-hover text-nowrap">
                                    <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Url</th>
                                        <th>Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    <tr v-for="contact in filteredContacts">

                                        <td>{{contact.name}}</td>
                                        <td>{{contact.url}}/{{contact.username}}</td>
                                        <td><button class="btn btn-sm btn-danger" v-on:click="removeContact(contact.id)"> <i class="fas fa-trash"></i></button></td>

                                    </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- /.card -->
                    </div>
                </div>
            </div>
        </section>
    </div>

</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "ContactList",
        data: function(){
            return {
                searchString: '',
            }
        },
        props: {
            contacts:{
                required: true,
            },
        },
        computed: {
            ...mapGetters([
            ]),

            filteredContacts(){
                if (this.contacts){
                    return this.contacts.filter( (contact) =>{
                        return !contact.name.toUpperCase().search(this.searchString.toUpperCase());
                    });
                }else{
                    return [];
                }

            }
        },
        mounted() {

        },
        methods:{
            ...mapActions([
                'deleteContact','showSuccessFlashMessage','showFlashMessage'
            ]),
            async removeContact(id){
                bootbox.confirm('Are you sure to delete the contact?',async (result)=>{
                    if(result){
                        await this.deleteContact(id).then((res)=>{
                            this.$toastr.s('Contact removed successfully.')
                        }).catch((err)=>{
                            this.$toastr.e(err);
                        });
                    }
                })
            }
        }

    }
</script>

<style scoped>

</style>