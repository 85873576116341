<template>
    <div class="col-md-4">
        <!-- Widget: user widget style 2 -->

        <div class="card card-widget widget-user-2" v-if="advert">
            <!-- Add the bg color to the header using any of the bg-* classes -->
            <div class="widget-user-header bg-info">
                <!-- /.widget-user-image -->
                <h4>Advert <button v-if="advert.type.toUpperCase() === type.toUpperCase()" class="btn btn-sm btn-danger float-right" v-on:click="deleteAdvert">Remove</button></h4>
            </div>
            <div class="card-footer p-0">
                <ul class="nav flex-column">


                    <li class="nav-item">
                                <span href="#" class="nav-link">
                                    <span class="text-bold">Adverted For Type:</span> <span class="float-right ">{{advert.type.toUpperCase()}}</span>
                                </span>
                    </li>

                    <li class="nav-item">
                                <span href="#" class="nav-link">
                                    <span class="text-bold">Adverted For Name:</span> <span class="float-right ">{{advert.name}}</span>
                                </span>
                    </li>
                    <li class="nav-item">
                                <span href="#" class="nav-link">
                                    <span class="text-bold">Start Date:</span> <span class="float-right ">{{advert.start_date}}</span>
                                </span>
                    </li>
                    <li class="nav-item">
                                <span href="#" class="nav-link">
                                    <span class="text-bold">End Date:</span> <span class="float-right ">{{advert.end_date}}</span>
                                </span>
                    </li>

                </ul>
            </div>

        </div>
        <div v-else class="card card-widget">
            <div class="card-header bg-info">
                <!-- /.widget-user-image -->
                <h4>Advert</h4>
            </div>
            <div class="card-body">
                <!-- Date -->
                <div class="form-group">
                    <label>Start Date:</label>
                    <div class="input-group date">
                        <input type="date" class="form-control" v-model="startDate">
                        <div class="input-group-append">
                            <div class="input-group-text"><i class="fa fa-calendar"></i></div>
                        </div>
                    </div>
                </div>
                <!-- /.form group --><!-- Date -->
                <div class="form-group">
                    <label>End Date:</label>
                    <div class="input-group date">
                        <input type="date" class="form-control" v-model="endDate">
                        <div class="input-group-append">
                            <div class="input-group-text"><i class="fa fa-calendar"></i></div>
                        </div>
                    </div>
                </div>
                <!-- /.form group -->
            </div>
            <div class="card-footer">
                <button type="submit" v-on:click="submitForm" class="btn btn-info"><span v-if="shopOwner">Request Advert</span><span v-else>Submit</span></button>
            </div>
            <!-- /.card-body -->
        </div>
        <!-- /.widget-user -->
    </div>
</template>

<script>
    import {mapActions} from "vuex"
    export default {

        name: "AdvertInfo",
        data(){
            return{
                startDate: '',
                endDate: '',
             }
        },
        computed:{
            advert(){
                return this.advertable.advert;

            }
        },
        props:{
            advertable:{
                required:true,
            },
            type:{
                required: true,
            },
            shopOwner:{
                default: false,
            }
        },
        created(){

        },
        methods:{
            ...mapActions(['storeAdvert','removeAdvert','showFlashMessage','showSuccessFlashMessage']),

            async submitForm(){

                let formData = new FormData();
                formData.append('type',this.type);
                formData.append('id',this.advertable.id);
                formData.append('start_date',this.startDate);
                formData.append('end_date',this.endDate);

                await this.storeAdvert(formData).then((res)=>{
                    this.$emit('set-advert',res.data);
                    this.$toastr.s('Successfully Added');
                }).catch((error)=>{
                    this.$toastr.e(error);
                });
            },

            async deleteAdvert(){
                bootbox.confirm("Are you sure to remove the Advert?",async (result) => {
                    if (result){
                        await this.removeAdvert(this.advert.id).then((res)=>{
                            this.$emit('remove-advert');
                            this.$toastr.s('Successfully Removed');
                        }).catch((error)=>{
                            this.$toastr.e(error);
                        });
                    }
                });

            }
        }
    }
</script>

<style scoped>

</style>