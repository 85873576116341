<template>
    <div class="card">
        <div class="card-header">
            <h3 class="card-title">Images</h3>
        </div>
        <!-- /.card-header -->
        <div class="card-body">
            <div id="carouselExampleIndicators" class="carousel slide pointer-event" data-ride="carousel">
                <ol class="carousel-indicators">
                    <li v-for="(image,index) in images" data-target="#carouselExampleIndicators" v-bind:key="index" :data-slide-to="index" :class="(index === 0)?'active':''"></li>
                </ol>
                <div class="carousel-inner">
                    <div v-for="(image,index) in images" v-bind:key="index" class="carousel-item" :class="(index === 0)?'active':''">
                        <img class="d-block w-100" :src="serverUrl + image.storageUrl" :alt="'image ' + index">
                    </div>

                </div>
                <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
        </div>
        <!-- /.card-body -->
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    export default {
        name: "ImageCarousel",
        props:{
            images:{
                required: true,
            }
        },
        computed:{
            ...mapGetters(['serverUrl'])
        }
    }
</script>

<style scoped>

</style>