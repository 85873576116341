import axios, * as others from "../../../axios";
import * as Vue from "vue";

const state = {
  services: [],
  service: {},
  timeUnits: [],
};
const mutations = {
  SET_SERVICES: (state, payload) => (state.services = payload),
  SET_SERVICE: (state, payload) => (state.service = payload),
  UPDATE_SERVICE: (state, service) => {
    const i = state.services.map((service) => service.id).indexOf(service.id);
    state.services.splice(i, 1);
    state.services.splice(i, 0, service);
  },
  DELETE_SERVICE: (state, id) => {
    const i = state.services.map((service) => service.id).indexOf(id);
    state.services.splice(i, 1);
  },
  DELETE_SERVICE_IMAGE: (state, id) => {
    const i = state.service.images.map((image) => image.id).indexOf(id);
    service.images.splice(i, 1);
  },
  SET_TIME_UNITS: (state,payload) => (state.timeUnits = payload),
  SET_ADVERT: (state, advert) => (state.service.advert = advert),
  REMOVE_ADVERT: (state) => (state.service.advert = null),
};
const actions = {
  async fetchServices({ commit }) {
    await axios
      .get("/shop-owner/services/")
      .then((response) => {
        commit("SET_SERVICES", response.data);
      })
      .catch((error) => {
        const response = error.response;
        response && response.data
          ? this._vm.$toastr.e(response.data.message)
          : console.log(error);
      });
  },
  async fetchService({ commit }, id) {
    state.service = {};
    await axios
      .get("/shop-owner/services/" + id)
      .then((response) => {
        commit("SET_SERVICE", response.data);
      })
      .catch((error) => {
        const response = error.response;
        response && response.data
          ? this._vm.$toastr.e(response.data.message)
          : console.log(error);
      });
  },

  async storeService({ commit }, formData) {
    return new Promise((resolve, reject) => {
      axios
        .post("/shop-owner/services", formData)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          error.response ? reject(error.response) : console.log(error);
        });
    });
  },

  async updateService({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/shop-owner/services/" + data.id, data.formData)
        .then((response) => {
          commit("UPDATE_SERVICE", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          error.response ? reject(error.response) : console.log(error);
        });
    });
  },

  async deleteService({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/shop-owner/services/" + id)
        .then((response) => {
          commit("DELETE_SERVICE", id);
          resolve(response.data);
        })
        .catch((error) => {
          error.response ? reject(error.response) : console.log(error);
        });
    });
  },

  async deleteServiceImage({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          "/shop-owner/images/" + payload.id + "/services/" + payload.serviceId
        )
        .then((response) => {
          commit("DELETE_SERVICE_IMAGE", payload.id);
          resolve(response.data);
        })
        .catch((error) => {
          error.response ? reject(error.response) : console.log(error);
        });
    });
  },

  async fetchTimeUnits({ commit }) {
    const response = await axios
      .get("/shop-owner/time-units")
      .catch(function(error) {
        error.response ? reject(error.response) : console.log(error);
      });

    commit("SET_TIME_UNITS", response.data);
  },

  setServiceAdvert({ commit }, advert) {
    commit("SET_ADVERT", advert);
  },

  removeServiceAdvert({ commit }) {
    commit("REMOVE_ADVERT");
  },
};
const getters = {
  services: (state) => state.services,
  service: (state) => state.service,
  timeUnits: (state) => state.timeUnits,
};

const serviceModule = {
  state,
  mutations,
  actions,
  getters,
};

export default serviceModule;
