<template>
  <div v-if="shop.id">
    <div
      class="content-wrapper"
      style="min-height: 1246.8px;"
    >
      <!-- Content Header (Page header) -->
      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 v-if="edit">Edit Address</h1>
              <h1 v-else>Add Address</h1>
            </div>
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item">
                  <router-link :to="'/home'">Home</router-link>
                </li>
                <li class="breadcrumb-item active">Add Address</li>
              </ol>
            </div>
          </div>
        </div>
        <!-- /.container-fluid -->
      </section>

      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <!-- left column -->
            <div class="col-md-6">
              <!-- general form elements -->
              <div class="card card-primary">
                <div class="card-header bg-info">
                  <h3 class="card-title">Address Form</h3>
                </div>
                <!-- /.card-header -->
                <!-- form start -->
                <form v-on:submit.prevent="submitForm" role="form">
                  <div class="card-body">
                    <div class="form-group">
                      <label for="input-country">Country</label>
                      <input
                        required
                        v-model="country"
                        type="text"
                        class="form-control"
                        :class="errors.country ? 'is-invalid' : ''"
                        id="input-country"
                        placeholder="Enter Country"
                      />
                      <div class="invalid-feedback">
                        <span v-if="errors.country && errors.country[0]">{{
                          errors.country[0]
                        }}</span>
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="input-city">City</label>
                      <input
                        required
                        v-model="city"
                        type="text"
                        class="form-control"
                        :class="errors.city ? 'is-invalid' : ''"
                        id="input-city"
                        placeholder="Enter City"
                      />
                      <div class="invalid-feedback">
                        <span v-if="errors.city && errors.city[0]">{{
                          errors.city[0]
                        }}</span>
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="input-sub-city">Sub City</label>
                      <input
                        required
                        v-model="subCity"
                        type="text"
                        class="form-control"
                        :class="errors.sub_city ? 'is-invalid' : ''"
                        id="input-sub-city"
                        placeholder="Enter Sub City"
                      />
                      <div class="invalid-feedback">
                        <span v-if="errors.sub_city && errors.sub_city[0]">{{
                          errors.sub_city[0]
                        }}</span>
                      </div>
                    </div>

                    <div class="form-group">
                      <label>Description</label>
                      <textarea
                        v-model="description"
                        class="form-control"
                        :class="errors.description ? 'is-invalid' : ''"
                        rows="3"
                        placeholder="Enter Description ..."
                      ></textarea>
                      <div class="invalid-feedback">
                        <span
                          v-if="errors.description && errors.description[0]"
                          >{{ errors.description[0] }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <!-- /.card-body -->

                  <div class="card-footer">
                    <div v-if="working" class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                    <br />
                    <button
                      :disabled="working"
                      type="submit"
                      id="submit-btn"
                      class="btn bg-info"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
              <!-- /.card -->
            </div>
            <!--/.col (left) -->
            <!-- right column -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </section>
      <!-- /.content -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AddressForm",
  data() {
    return {
      country: "",
      city: "",
      subCity: "",
      description: "",
      working: false,
      errors: []
    };
  },
  props: {
    edit: {
      default: false,
    },
  },
  watch: {
    address() {
      if (this.address.id) {
        this.setData();
      } else {
        this.resetData();
      }
    },
  },
  computed: {
    ...mapGetters(["shop","displayErrorMessages"]),
    address() {
      if (this.edit) {
        return this.shop.address;
      } else {
        return {};
      }
    },
  },

  mounted() {
    this.setData();
  },

  methods: {
    ...mapActions([
      "storeAddress",
      "updateAddress",
      "showFlashMessage",
      "showSuccessFlashMessage",
    ]),
    async submitForm() {
      
      if (!this.working) {
        this.working = true;

        let formData = new FormData();

        formData.append("description", this.description);
        formData.append("country", this.country);
        formData.append("city", this.city);
        formData.append("sub_city", this.subCity);

        formData.append("shop_id", this.shop.id);

        if (this.edit) {
          formData.append("_method", "PUT");
          await this.updateAddress({ formData: formData, id: this.address.id })
            .then((response) => {
              this.$toastr.s("Address Updated Successfully");
              this.$router.push("/show/shop/").catch((e) => {
                console.log(e);
              });
            })
            .catch((error) => {
              this.errors = this.displayErrorMessages(error);
            });
        } else {
          await this.storeAddress(formData)
            .then((response) => {
              this.$toastr.s("Address Added Successfully");
              this.$router.push("/show/shop/").catch((e) => {
                console.log(e);
              });
            })
            .catch((error) => {
              this.errors = this.displayErrorMessages(error);
            });
        }

        this.working = false;
      }
    },

    setData() {
      if (this.address.id) {
        this.country = this.address.country;
        this.city = this.address.city;
        this.subCity = this.address.sub_city;
        this.description = this.address.description;
      }
    },

    resetData() {
      this.country = "";
      this.city = "";
      this.subCity = "";
      this.description = "";
    },
  },
};
</script>

<style scoped></style>
