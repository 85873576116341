<template>
  <div v-if="template.id">
    <div class="content-wrapper">
      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0 text-dark">Shop Template</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item">
                  <router-link :to="'/home'">Home</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link :to="'/list/template'"
                    >Template List</router-link
                  >
                </li>
                <li class="breadcrumb-item active">
                  {{ template.name }} (Template)
                </li>
              </ol>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </section>

      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-5">
              <!-- Widget: user widget style 2 -->
              <div class="card card-widget widget-user-2">
                <!-- Add the bg color to the header using any of the bg-* classes -->
                <div class="widget-user-header bg-dark">
                  <div class="widget-user-image">
                    <img
                      v-if="template.images[0]"
                      :src="serverUrl + template.images[0].storageUrl"
                      class="img-circle elevation-2"
                      alt="User Avatar"
                    />
                    <img
                      v-else
                      class="img-circle elevation-2"
                      src="/img/store.png"
                      alt="User Avatar"
                    />
                  </div>
                  <!-- /.widget-user-image -->
                  <h3 class="widget-user-username">
                    {{ template.name }}
                    <small
                      v-if="
                        shopTemplate.id &&
                          shopTemplate.template.id == template.id
                      "
                      >(Selected)</small
                    >
                    <span class="float-right">
                      <slot
                        v-if="
                          shopTemplate.id &&
                            shopTemplate.template.id == template.id
                        "
                      >
                        <router-link
                          :to="'/edit/shop-template/' + shopTemplate.id"
                          class="btn btn-sm btn-secondary"
                        >
                          <i class="fas fa-edit"></i
                        ></router-link>
                        <button
                          v-on:click="deselectShopTemplate(shopTemplate.id)"
                          class="btn btn-sm btn-outline-danger"
                        >
                          <i class="fas fa-trash"></i>
                        </button>
                      </slot>
                      <router-link
                        v-else
                        :to="'/select/template/' + template.id"
                        class="btn btn-sm bg-info"
                        >Select Template</router-link
                      >
                    </span>
                  </h3>
                </div>
                <div class="card-footer p-0">
                  <ul class="nav flex-column">
                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">ID</span>
                        <span class="float-right">{{ template.id }}</span>
                      </span>
                    </li>

                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Name</span>
                        <span class="float-right">{{ template.name }}</span>
                      </span>
                    </li>

                    <slot
                      v-if="
                        shopTemplate.id &&
                          shopTemplate.template.id == template.id
                      "
                    >
                      <li class="nav-item">
                        <span href="#" class="nav-link">
                          <span class="text-bold">Selected Primary Color</span>
                          <label
                            class="btn btn-default text-center active float-right"
                          >
                            <i
                              class="fas fa-circle fa-2x "
                              :style="
                                'color:' + shopTemplate.primary_color + ';'
                              "
                            ></i>
                          </label>
                        </span>
                      </li>
                      <li class="nav-item">
                        <span href="#" class="nav-link">
                          <span class="text-bold"
                            >Selected Secondary Color</span
                          >
                          <label
                            class="btn btn-default text-center active float-right"
                          >
                            <i
                              class="fas fa-circle fa-2x "
                              :style="
                                'color:' + shopTemplate.secondary_color + ';'
                              "
                            ></i>
                          </label>
                        </span>
                      </li>
                    </slot>

                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Price</span>
                        <span class="float-right">{{ template.price }}</span>
                      </span>
                    </li>
                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Designed By</span>
                        <span class="float-right">{{
                          template.designed_by
                        }}</span>
                      </span>
                    </li>
                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Description</span>
                        <span class="float-right">{{
                          template.description
                        }}</span>
                      </span>
                    </li>
                    <!--<li class="nav-item">
                                <span href="#" class="nav-link">
                                    <span class="text-bold">Shops</span> <span class="float-right badge bg-primary"><span v-if="template.shops">{{template.shops.length}}</span><span v-else>0</span></span>
                                </span>
                                        </li>
                                        <li class="nav-item">
                                <span href="#" class="nav-link">
                                    <span class="text-bold">Date Added</span> <span class="float-right ">{{getDate(template.created_at)}}</span>
                                </span>
                                        </li>-->
                  </ul>
                </div>
              </div>
              <!-- /.widget-user -->
            </div>
            <div v-if="template.images" class="col-12 col-sm-7">
              <h3 class="d-inline-block d-sm-none">{{ template.name }}</h3>
              <div class="col-12">
                <img
                  v-if="template.images[0]"
                  :src="serverUrl + template.images[0].storageUrl"
                  id="product-image"
                  class="product-image"
                  alt="Product Image"
                />
              </div>
              <div class="col-12 product-image-thumbs">
                <div
                  v-for="(image, index) in template.images"
                  v-bind:key="index"
                >
                  <div
                    class="product-image-thumb"
                    :class="image.id === activeImageId ? 'bg-dark' : ''"
                    v-on:click="setImage(image)"
                  >
                    <img
                      :src="serverUrl + image.storageUrl"
                      alt="Product Image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ShowTemplate",
  data() {
    return {
      activeImageId: null,
    };
  },
  components: {},
  computed: {
    ...mapGetters(["shopTemplate", "template", "getDate", "shop", "serverUrl"]),
  },
  props: {
    id: {
      required: true,
    },
  },
  mounted() {
    if (this.id) {
      this.fetchTemplate(this.id);
    }
    if (this.shop.id) {
      this.fetchShopTemplateByShop(this.shop.id);
    }
  },
  methods: {
    ...mapActions([
      "fetchTemplate",
      "fetchShopTemplateByShop",
      "deleteShopTemplate",
    ]),
    setImage(image) {
      document.getElementById("product-image").src =
        this.serverUrl + image.storageUrl;
      this.activeImageId = image.id;
    },
    async deselectShopTemplate(id) {
      bootbox.confirm(
        "Are you sure to deselect the chosen Template",
        async (result) => {
          if (result) {
            await this.deleteShopTemplate(id)
              .then((res) => {
                this.$toastr.s("Template deselected successfully.");
              })
              .catch((err) => {
                this.$toastr.e(err);
              });
          }
        }
      );
    },
  },
};
</script>

<style scoped></style>
