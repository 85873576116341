import axios, * as others from "../../../axios";
import * as Vue from "vue";

const state = {
  orders: [],
  order: {},
};
const mutations = {
  SET_ORDERS: (state, payload) => (state.orders = payload),
  SET_ORDER: (state, payload) => (state.order = payload),
  UPDATE_ORDERS: (state, orders) => {
    orders.forEach((order) => {
      const i = state.orders.map((order) => order.id).indexOf(order.id);

      state.orders.splice(i, 1);
      state.orders.splice(i, 0, order);
    });
  },
  UPDATE_ORDER: (state, order) => {
    const i = state.orders.map((order) => order.id).indexOf(order.id);
    state.orders.splice(i, 1);
    state.orders.splice(i, 0, order);
  },
  DELETE_ORDER: (state, id) => {
    const i = state.orders.map((order) => order.id).indexOf(id);
    state.orders.splice(i, 1);
  },
};
const actions = {
  fetchOrders({ commit},shopId) {
    axios
      .get("/shop-owner/shops/"+shopId+"/orders/")
      .then((response) => {
        commit("SET_ORDERS", response.data);
      })
      .catch((error) => {
        const response = error.response;
        response && response.data
          ? this._vm.$toastr.e(response.data.message)
          : console.log(error);
      });
  },
  fetchOrder({ commit }, id) {
    state.order = {};
    axios
      .get("/shop-owner/orders/" + id)
      .then((response) => {
        commit("SET_ORDER", response.data);
      })
      .catch((error) => {
        const response = error.response;
        response && response.data
          ? this._vm.$toastr.e(response.data.message)
          : console.log(error);
      });
  },

  async storeOrder({ commit }, formData) {
    return new Promise((resolve, reject) => {
      axios
        .post("/shop-owner/orders", formData)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          error.response ? reject(error.response) : console.log(error);
        });
    });
  },

  async updateOrders({ commit }, payload) {
    //TODO: use put request
    return new Promise((resolve, reject) => {
      axios
        .post("/shop-owner/orders/", {data: payload })
        .then((response) => {
          commit("UPDATE_ORDERS", response.data);

          resolve(response.data);
        })
        .catch((error) => {
          error.response ? reject(error.response) : console.log(error);
        });
    });
  },

  async updateOrder({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/shop-owner/orders/" + data.id, data.formData)
        .then((response) => {
          commit("UPDATE_ORDER", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          error.response ? reject(error.response) : console.log(error);
        });
    });
  },

  async deleteOrder({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/shop-owner/orders/" + id)
        .then((response) => {
          commit("DELETE_ORDER", id);
          resolve(response.data);
        })
        .catch((error) => {
          error.response ? reject(error.response) : console.log(error);
        });
    });
  },
};
const getters = {
  orders: (state) => state.orders,
  order: (state) => state.order,
};

const orderModule = {
  state,
  mutations,
  actions,
  getters,
};

export default orderModule;
