<template>
  <div class="content-wrapper" v-if="product.id">
    <section class="content">
      <!-- Default box -->
      <div class="card card-solid">
        <div class="card-body">
          <div class="row">
            <div v-if="product.images" class="col-12 col-sm-6">
              <h3 class="d-inline-block d-sm-none">{{ product.name }}</h3>
              <div class="col-12">
                <img
                  v-if="product.images[0]"
                  :src="serverUrl + product.images[0].storageUrl"
                  id="product-image"
                  class="product-image"
                  alt="Category Image"
                />
              </div>
              <div class="col-12 product-image-thumbs">
                <div
                  v-for="(image, index) in product.images"
                  v-bind:key="index"
                >
                  <button
                    class="btn btn-outline-danger btn-sm"
                    v-on:click="removeImage(image.id)"
                  >
                    <i class="fas fa-trash"></i>
                  </button>
                  <div
                    class="product-image-thumb"
                    :class="image.id === activeImageId ? 'bg-dark' : ''"
                    v-on:click="setImage(image)"
                  >
                    <img
                      :src="serverUrl + image.storageUrl"
                      alt="Category Image"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <h3 class="my-3">
                {{ product.name.toUpperCase() }}
                <small v-if="product.category"
                  ><small
                    ><router-link :to="'/show/category/' + product.category.id"
                      >({{ product.category.name }})</router-link
                    ></small
                  ></small
                ><span class="float-right"
                  ><router-link
                    :to="'/edit/product/' + product.id"
                    class="btn btn-sm btn-secondary"
                  >
                    <i class="fas fa-edit"></i></router-link
                  ><button
                    v-on:click="removeProduct(product.id)"
                    class="btn btn-sm btn-outline-danger"
                  >
                    <i class="fas fa-trash"></i></button
                ></span>
              </h3>
              <hr />
              <h5>
                ID: {{ product.id }}
              </h5>
              <hr />
              <h5 v-if="product.category.has_quantity === 1">
                Quantity: {{ product.quantity }}
              </h5>
              <h5 v-if="product.sold_out === 1">
                <span class="badge bg-danger">Sold Out</span>
              </h5>
              <hr />
              <h5 v-if="product.tags">
                Tags: |
                <span v-for="tag in product.tags" v-bind:key="tag.id"
                  ><small
                    ><router-link :to="'/show/tag/' + tag.id">{{
                      tag.name
                    }}</router-link></small
                  >
                  |
                </span>
              </h5>
              <hr />
              <slot v-if="product.properties">
                <slot v-if="product.properties.colors.length > 0">
                  <h5>Available Colors</h5>
                  <div class="btn-group btn-group-toggle" data-toggle="buttons">
                    <label
                      v-for="color in product.properties.colors"
                      v-bind:key="color.id"
                      class="btn btn-default text-center active"
                    >
                      <input
                        type="radio"
                        name="color_option"
                        id="color_option1"
                        autocomplete="off"
                        checked=""
                      />
                      <i
                        class="fas fa-circle fa-2x "
                        :style="'color:' + color + ';'"
                      ></i>
                    </label>
                  </div>
                </slot>

                <slot v-if="product.properties.sizes.length > 0">
                  <h5 class="mt-3">Available Sizes</h5>
                  <div class="btn-group btn-group-toggle" data-toggle="buttons">
                    <label
                      v-for="size in product.properties.sizes"
                      v-bind:key="size.id"
                      class="btn btn-default text-center"
                    >
                      <input
                        type="radio"
                        name="color_option"
                        id="size_option1"
                        autocomplete="off"
                      />
                      <span class="text-xl">{{ size.toUpperCase() }}</span>
                    </label>
                  </div>
                </slot>

                <slot v-if="product.properties.area.width">
                  <h5 class="mt-3">Area</h5>
                  <div class="btn-group btn-group-toggle" data-toggle="buttons">
                    <label class="btn btn-default text-center">
                      <input
                        type="radio"
                        name="color_option"
                        id="area_option1"
                        autocomplete="off"
                      />
                      <span class="text-xl"
                        >{{ this.product.properties.area.width }} X
                        {{ this.product.properties.area.height }}
                        {{ this.product.properties.area.measurement }}</span
                      >
                    </label>
                  </div>
                </slot>
              </slot>

              <div class="bg-gray py-2 px-3 mt-4">
                <h2 class="mb-0">{{ product.price }} ETB</h2>
                <h4 v-if="product.discount" class="mt-0">
                  Discount: {{ product.discount }} ETB
                  <small
                    >({{
                      ((product.discount / product.price) * 100).toFixed()
                    }}%)
                  </small>
                </h4>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <nav class="w-100">
              <div class="nav nav-tabs" id="category-tab" role="tablist">
                <a
                  v-if="product.description"
                  class="nav-item nav-link active"
                  id="category-desc-tab"
                  data-toggle="tab"
                  href="#category-desc"
                  role="tab"
                  aria-controls="category-desc"
                  aria-selected="true"
                  >Description</a
                >
                <a
                  class="nav-item nav-link"
                  id="category-comments-tab"
                  data-toggle="tab"
                  href="#category-comments"
                  role="tab"
                  aria-controls="category-comments"
                  aria-selected="false"
                  >Comments</a
                >
                <a
                  class="nav-item nav-link"
                  id="category-rating-tab"
                  data-toggle="tab"
                  href="#category-rating"
                  role="tab"
                  aria-controls="category-rating"
                  aria-selected="false"
                  >Rating</a
                >
              </div>
            </nav>
            <div class="tab-content p-3" id="nav-tabContent">
              <div
                v-if="product.description"
                class="tab-pane fade show active"
                id="category-desc"
                role="tabpanel"
                aria-labelledby="category-desc-tab"
              >
                {{ product.description }}
              </div>
              <div
                class="tab-pane fade"
                id="category-comments"
                role="tabpanel"
                aria-labelledby="category-comments-tab"
              >
                ...
              </div>
              <div
                class="tab-pane fade"
                id="category-rating"
                role="tabpanel"
                aria-labelledby="category-rating-tab"
              >
                ...
              </div>
            </div>
          </div>
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ProductShow",
  data() {
    return {
      activeId: null,
    };
  },
  components: {},
  mounted() {
    this.fetchProduct(this.id);
  },
  computed: {
    ...mapGetters(["getDate", "product", "serverUrl"]),
    activeImageId() {
      if (this.activeId) {
        return this.activeId;
      } else if (this.product.images[0]) {
        return this.product.images[0].id;
      } else {
        return null;
      }
    },
  },
  props: {
    id: {
      required: true,
    },
  },
  methods: {
    ...mapActions([
      "fetchProduct",
      "setProductAdvert",
      "removeProductAdvert",
      "deleteProduct",
      "deleteProductImage",
      "showSuccessFlashMessage",
      "showFlashMessage",
    ]),
    setImage(image) {
      document.getElementById("product-image").src =
        this.serverUrl + image.storageUrl;
      this.activeId = image.id;
    },
    async removeProduct(id) {
      bootbox.confirm(
        "Are you sure to delete the Product and all its related data?",
        async (result) => {
          if (result) {
            const categoryId = this.product.category_id;
            await this.deleteProduct(id)
              .then((res) => {
                this.$toastr.s("Product removed successfully.");
                this.$router
                  .push("/show/category/" + categoryId)
                  .catch((err) => {});
              })
              .catch((err) => {
                this.$toastr.e(err);
              });
          }
        }
      );
    },
    async removeImage(id) {
      bootbox.confirm("Are you sure to delete the image?", async (result) => {
        if (result) {
          await this.deleteProductImage({ id: id, productId: this.product.id })
            .then((res) => {
              this.$toastr.s("Image removed successfully.");
            })
            .catch((err) => {
              this.$toastr.e(err);
            });
        }
      });
    },
  },
};
</script>

<style scoped></style>
