import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'overlayscrollbars/css/OverlayScrollbars.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import { OverlayScrollbarsPlugin } from 'overlayscrollbars-vue';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-vue';
import MultiSelect from 'vue-multiselect'
import VueToastr from "vue-toastr";


Vue.config.productionTip = false

Vue.use(VueToastr, {
  /* OverWrite Plugin Options if you need */
});

 // register globally
 Vue.component('multi-select', MultiSelect);
 // global registration
 Vue.component('overlay-scrollbars', OverlayScrollbarsComponent);

 Vue.use(OverlayScrollbarsPlugin);


new Vue({
  router,
  store,
  render: h => h(App),
  created(){
    this.$store.dispatch('bootstrap');
  }
}).$mount('#app')
