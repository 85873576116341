<template>
  <div :class="navigation ? 'content-wrapper' : ''">
    <section class="content-header" v-if="navigation">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">Tag List</h1>
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="'/home'">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Tag List</li>
            </ol>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">
                  Tag List
                  <span class="badge badge-info right">{{
                    filteredTags.length
                  }}</span>
                </h3>

                <div class="card-tools">
                  <div class="input-group input-group-sm" style="width: 150px;">
                    <input
                      type="text"
                      v-model="searchString"
                      name="table_search"
                      class="form-control float-right"
                      placeholder="Search"
                    />

                    <div class="input-group-append">
                      <button type="submit" class="btn btn-default">
                        <i class="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                  <br />
                  <div class="input-group input-group-sm" style="width: 150px;">
                    <router-link
                      :to="'/add/tag'"
                      class="btn btn-outline-primary float-right"
                    >
                      <i class="fas fa-plus"></i> Add Tag
                    </router-link>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->

              <div class="card-body table-responsive p-0">
                <table class="table table-hover text-nowrap">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th v-show="showCategory">Category</th>
                      <th>Added at</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="tag in filteredTags" v-bind:key="tag.id">
                      <td>
                        <router-link :to="'/show/tag/' + tag.id">{{
                          tag.name
                        }}</router-link>
                      </td>
                      <td v-show="showCategory">
                        <span>{{ tag.category_name }}</span>
                      </td>
                      <td>{{ getDate(tag.created_at) }}</td>

                      <td>
                        <router-link
                          :to="'/edit/tag/' + tag.id"
                          class="btn btn-sm bg-teal"
                        >
                          <i class="fas fa-edit"></i></router-link
                        ><button
                          class="btn btn-sm btn-danger"
                          v-on:click="removeTag(tag.id)"
                        >
                          <i class="fas fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TagList",
  data: function() {
    return {
      searchString: "",
      listPlus: false,
    };
  },
  props: {
    showCategory: {
      type: Boolean,
      default: true,
      required: false,
    },
    navigation: {
      default: true,
    },
    categoryId: {},
  },
  computed: {
    ...mapGetters(["tags", "getDate"]),

    filteredTags() {
      if (this.tags) {
        return this.tags.filter((tag) => {
          return !tag.name
            .toUpperCase()
            .search(this.searchString.toUpperCase());
        });
      } else {
        return [];
      }
    },
  },
  mounted() {
    if (this.categoryId) {
      this.fetchCategoryTags(this.categoryId);
    } else {
      this.fetchTags();
    }
  },
  methods: {
    ...mapActions([
      "fetchTags",
      "fetchCategoryTags",
      "deleteTag",
      "showSuccessFlashMessage",
      "showFlashMessage",
    ]),
    async removeTag(id) {
      bootbox.confirm(
        "Are you sure to remove the Tag and all related contents (Associations with products)",
        async (result) => {
          if (result) {
            await this.deleteTag(id)
              .then((res) => {
                this.$toastr.s("Tag Successfully removed");
              })
              .catch((err) => {
                this.$toastr.e(err);
              });
          }
        }
      );
    },
  },
};
</script>

<style scoped></style>
