import axios, * as others from "../../../axios";
import * as Vue from "vue";

const state = {
  products: [],
  product: {},
  properties: [],
};
const mutations = {
  SET_PRODUCTS: (state, payload) => (state.products = payload),
  SET_PRODUCT: (state, payload) => (state.product = payload),
  UPDATE_PRODUCT: (state, product) => {
    const i = state.products.map((product) => product.id).indexOf(product.id);
    state.products.splice(i, 1);
    state.products.splice(i, 0, product);
  },
  DELETE_PRODUCT: (state, id) => {
    const i = state.products.map((product) => product.id).indexOf(id);
    state.products.splice(i, 1);
  },
  DELETE_PRODUCT_IMAGE: (state, id) => {
    const i = state.product.images.map((image) => image.id).indexOf(id);
    state.product.images.splice(i, 1);
  },
  SET_PROPERTIES: (state, payload) => (state.properties = payload),
  SET_ADVERT: (state, advert) => (state.product.advert = advert),
  REMOVE_ADVERT: (state) => (state.product.advert = null),
};
const actions = {
  fetchProducts({ commit }) {
    axios
      .get("/shop-owner/products/")
      .then((response) => {
        commit("SET_PRODUCTS", response.data);
      })
      .catch((error) => {
        const response = error.response;
        response && response.data
          ? this._vm.$toastr.e(response.data.message)
          : console.log(error);
      });
  },
  fetchCategoryProducts({ commit }, id) {
    axios
      .get("/shop-owner/categories/" + id + "/products")
      .then((response) => {
        commit("SET_PRODUCTS", response.data);
      })
      .catch((error) => {
        const response = error.response;
        response && response.data
          ? this._vm.$toastr.e(response.data.message)
          : console.log(error);
      });
  },
  fetchTagProducts({ commit }, id) {
    axios
      .get("/shop-owner/tags/" + id + "/products")
      .then((response) => {
        commit("SET_PRODUCTS", response.data);
      })
      .catch((error) => {
        const response = error.response;
        response && response.data
          ? this._vm.$toastr.e(response.data.message)
          : console.log(error);
      });
  },

  fetchProduct({ commit }, id) {
    state.product = {};
    axios
      .get("/shop-owner/products/" + id)
      .then((response) => {
        commit("SET_PRODUCT", response.data);
      })
      .catch((error) => {
        const response = error.response;
        response && response.data
          ? this._vm.$toastr.e(response.data.message)
          : console.log(error);
      });
  },

  async storeProduct({ commit }, formData) {
    return new Promise((resolve, reject) => {
      axios
        .post("/shop-owner/products", formData)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          error.response ? reject(error.response) : console.log(error);
        });
    });
  },

  async updateProduct({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/shop-owner/products/" + data.id, data.formData)
        .then((response) => {
          commit("UPDATE_PRODUCT", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          error.response ? reject(error.response) : console.log(error);
        });
    });
  },

  async deleteProduct({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/shop-owner/products/" + id)
        .then((response) => {
          commit("DELETE_PRODUCT", id);
          resolve(response.data);
        })
        .catch((error) => {
          error.response ? reject(error.response) : console.log(error);
        });
    });
  },

  async deleteProductImage({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          "/shop-owner/images/" + payload.id + "/products/" + payload.productId
        )
        .then((response) => {
          commit("DELETE_PRODUCT_IMAGE", payload.id);
          resolve(response.data);
        })
        .catch((error) => {
          error.response ? reject(error.response) : console.log(error);
        });
    });
  },

  async fetchProperties({ commit }) {
    const response = await axios
      .get("/shop-owner/properties")
      .catch(function(error) {
        error.response ? reject(error.response) : console.log(error);
      });

    commit("SET_PROPERTIES", response.data);
  },

  setProductAdvert({ commit }, advert) {
    commit("SET_ADVERT", advert);
  },

  removeProductAdvert({ commit }) {
    commit("REMOVE_ADVERT");
  },
};
const getters = {
  products: (state) => state.products,
  product: (state) => state.product,
  productsByPriceAsc: (state) => {
    let products = state.products;

    if (products.length > 0) {
      products.sort((a, b) => {
        return a.price - b.price;
      });
    }

    return products;
  },
  productsByOrderAsc: (state) => {
    let products = state.products;
    if (products.length > 0) {
      products.sort((a, b) => {
        return a.number_of_orders - b.number_of_orders;
      });
    }
    return products;
  },
  properties: (state) => state.properties,
};

const productModule = {
  state,
  mutations,
  actions,
  getters,
};

export default productModule;
