<template>
    <div class="content-wrapper" style="min-height: 1246.8px;">
        <section class="content-header">
            <div class="container-fluid">
                <h1>General Statistics</h1>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">

                    <div class="col-md-12">
                        <div class="card card-primary">
                            <div class="card-header">
                                <h3 class="card-title">Product Price Statistics</h3>
                                <div class="card-tools">
                                    <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="chart">
                                    <bar-chart v-if="priceChartData && priceOptions" :chartdata="priceChartData" :options="priceOptions"></bar-chart>
                                </div>
                            </div>
                        </div>
                    </div>
                <div class="col-md-12">
                        <div class="card card-info">
                            <div class="card-header">
                                <h3 class="card-title">Product Orders Statistics</h3>
                                <div class="card-tools">
                                    <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="chart">
                                    <bar-chart v-if="orderChartData && orderOptions" :chartdata="orderChartData" :options="orderOptions"></bar-chart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

        </section>

    </div>
</template>

<script>
    import {mapGetters,mapActions} from 'vuex';
    import BarChart from '../Charts/BarChart'
    export default {
        name: "GeneralStats",
        components:{
            BarChart,
        },
        data(){
            return{
                priceChartData: null,
                priceOptions: null,
                orderChartData: null,
                orderOptions: null,
            }
        },
        watch:{

        },
        mounted(){
            this.fetchProducts();
            this.unwatchProductsByPrice = this.$watch('productsByPriceAsc', (newVal) => {
                if (newVal) {
                    if (this.productsByPriceAsc.length > 0){
                        this.fillPriceData();
                    }
                    this.unwatchProductsByPrice();
                }
            });
            this.unwatchProductsByOrder = this.$watch('products', (newVal) => {
                if (newVal) {
                    if (this.products.length > 0){
                        this.fillOrderData();
                    }
                    this.unwatchProductsByOrder();
                }
            });
            if (this.productsByPriceAsc.length > 0){
                this.fillPriceData();
            }
            if (this.products.length > 0){
                this.fillOrderData();
            }
        },
        computed:{
            ...mapGetters(['productsByPriceAsc','products']),
        },
        methods:{
            ...mapActions(['fetchProducts']),
            productPriceNames(){
                let names = [];
                this.productsByPriceAsc.forEach((product)=>{
                    names.push(product.name)
                });

                return names;
            },
            productOrderNames(){
                let names = [];
                this.products.forEach((product)=>{
                    names.push(product.name)
                });

                return names;
            },
            productPrices(){
                let prices = [];
                this.productsByPriceAsc.forEach((product)=>{
                    prices.push(product.price)
                });

                return prices;
            },
            productOrders(){
                let orders = [];
                this.products.forEach((product)=>{
                    orders.push(product.number_of_orders)
                });

                return orders;
            },
            fillPriceData(){
                this.priceChartData = {
                    labels: this.productPriceNames(),
                    datasets: [
                        {
                            label: 'Data One',
                            backgroundColor: '#007bff',
                            pointBackgroundColor: 'white',
                            borderWidth: 1,
                            pointBorderColor: '#249EBF',
                            data: this.productPrices()
                        }
                    ]
                };
                this.priceOptions = {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            },
                            gridLines: {
                                display: true
                            }
                        }],
                        xAxes: [{
                            ticks: {
                                beginAtZero: true
                            },
                            gridLines: {
                                display: false
                            }
                        }]
                    },
                    legend: {
                        display: false
                    },
                    tooltips: {
                        enabled: true,
                        mode: 'single',
                        callbacks: {
                            label: function(tooltipProducts, data) {
                                return tooltipProducts.yLabel + ' ETB' ;
                            }
                        }
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    height: 200
                };
            },
            fillOrderData(){
                this.orderChartData = {
                    labels: this.productOrderNames(),
                    datasets: [
                        {
                            label: 'Data One',
                            backgroundColor: '#17a2b8',
                            pointBackgroundColor: 'white',
                            borderWidth: 1,
                            pointBorderColor: '#249EBF',
                            data: this.productOrders()
                        }
                    ]
                };
                this.orderOptions = {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            },
                            gridLines: {
                                display: true
                            }
                        }],
                        xAxes: [{
                            ticks: {
                                beginAtZero: true
                            },
                            gridLines: {
                                display: false
                            }
                        }]
                    },
                    legend: {
                        display: false
                    },
                    tooltips: {
                        enabled: true,
                        mode: 'single',
                        callbacks: {
                            label: function(tooltipProducts, data) {
                                return tooltipProducts.yLabel;
                            }
                        }
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    height: 200
                };
            }
        }

    }

</script>

<style scoped>

</style>








