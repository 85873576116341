<template>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">Order List</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><router-link :to="'/home'">Home</router-link></li>
                            <li class="breadcrumb-item active">Order List</li>
                        </ol>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Order List <span class="badge badge-info right">{{filteredOrders.length}}</span></h3>

                                <div class="card-tools">
                                    <div class="input-group input-group-sm" style="width: 150px;">
                                       <!-- <div class="input-group-append">
                                            <button v-if="listPlus" v-on:click="listPlus = false" class="btn btn-outline-primary"><i class="fas fa-list"></i></button>
                                            <button v-else v-on:click="listPlus = true" type="submit" class="btn btn-outline-primary"><i class="fas fa-image"></i></button>
                                        </div>-->
                                        <input type="text" v-model="searchString" name="table_search" class="form-control float-right" placeholder="Search">

                                        <div class="input-group-append">
                                            <button type="submit" class="btn btn-default"><i class="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                    <button class="btn btn-outline-secondary float-right" v-if="orderUpdates.length > 1" @click="editOrders()" >Update All</button>
                                </div>
                            </div>
                            <!-- /.card-header  -->
                            <div v-if="listPlus" class="card-body pb-0">
                                <div class="row d-flex align-items-stretch">
                                    <div v-for="order in filteredOrders"  v-bind:key="order.id" class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch">
                                        <div class="card bg-light">
                                            <div class="card-header text-muted border-bottom-0">

                                            </div>
                                            <div class="card-body pt-0">
                                                <div class="row">
                                                    <div class="col-7">
                                                        <h2 class="lead"><b>{{order.product.name}}</b></h2>

                                                        <ul class="ml-4 mb-0 fa-ul text-muted">


                                                        </ul>
                                                    </div>
                                                    <div class="col-5 text-center">
                                                        <img v-if="order.product.images[0]" :src="order.product.images[0].storageUrl" alt="" class="img-circle img-fluid">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-footer">
                                                <div class="text-right">
                                                    <router-link :to="'/show/order/'+order.id" class="btn btn-sm btn-primary">
                                                        <i class="fas fa-eye"></i> View
                                                    </router-link>
                                                    <router-link :to="'/edit/order/'+order.id" class="btn btn-sm bg-teal">
                                                        <i class="fas fa-edit"></i> Edit
                                                    </router-link>
                                                    <a v-on:click="removeOrder(order.id)" href="#" class="btn btn-sm btn-danger">
                                                        <i class="fas fa-trash"></i> Delete
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- /.card-body -->
                            </div>
                            <div v-else class="card-body table-responsive p-0">
                                <table class="table table-hover text-nowrap">
                                    <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>User</th>
                                        <th>Send Mail</th>
                                        <th>Product</th>
                                        <th>Quantity</th>
                                        <th>Total Price</th>
                                        <th>Ordered at</th>
                                        <th>Payment</th>
                                        <th>Delivered</th>
                                        <th>Canceled</th>
                                        <th>Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    <tr v-for="order in filteredOrders" v-bind:key="order.id">

                                        <td><router-link :to="'/show/order/'+order.id">{{order.id}} </router-link></td>
                                        <td><router-link :to="'/show/order/'+order.id">{{order.user.name}}</router-link></td>
                                        <td><a class="btn btn-secondary btn-sm" :href = "'mailto: ' + order.user.email"><i class="fas fa-envelope"></i></a></td>
                                        <td><router-link :to="'/show/product/'+order.product.id">{{order.product.name}}</router-link></td>
                                        <td>{{order.quantity}}</td>
                                        <td>{{order.quantity * order.product.price}} ETB</td>
                                        <td>{{getDateFromNow(order.created_at)}}</td>
                                        <td><label><input type="checkbox" v-model="order.payment_made_at" v-on:change="addUpdates(order.id)"></label></td>
                                        <td><label><input type="checkbox" v-model="order.delivered_at" v-on:change="addUpdates(order.id)"></label></td>
                                        <td><label><input type="checkbox" v-model="order.canceled_at" v-on:change="addUpdates(order.id)"></label></td>
                                        <td><button  class="btn btn-sm btn-primary" @click="editOrders([order.id])" :disabled="!orderUpdates.includes(order.id)">Update</button></td>

                                    </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- /.card -->
                    </div>
                </div>
            </div>
        </section>
    </div>

</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "OrderList",
        data: function(){
            return {
                searchString: '',
                listPlus: false,
                orderUpdates: [],
            }
        },
        props: {

        },

        computed: {
            ...mapGetters([
                'shop','getDateFromNow','orders',
            ]),

            filteredOrders(){
                if (this.orders.length > 0){
                    return this.orders.filter( (order) =>{
                        return !order.product.name.toUpperCase().search(this.searchString.toUpperCase());
                    });
                }else{
                    return [];
                }

            }
        },
        mounted() {
            if(this.shop.id){
                
                this.fetchOrders(this.shop.id)
            }
            
        },
        methods:{
            ...mapActions([
                'fetchOrders','deleteOrder', 'updateOrders', 'showSuccessFlashMessage','showFlashMessage'
            ]),
            async removeOrder(id){
                bootbox.confirm('Are you sure to delete the order and all its related data (products, tags)?',async (result)=>{
                    if(result){
                        await this.deleteOrder(id).then((res)=>{
                            this.showSuccessFlashMessage('Order removed successfully.')
                        }).catch((err)=>{
                            this.showFlashMessage(err);
                        });
                    }
                })
            },
            async editOrders(){
                let formData = [];
                let orders = this.orderUpdates;

                for (let i = 0; i < orders.length; i++){
                    let payment_made_at;
                    let delivered_at;
                    let canceled_at;

                    this.orders.forEach((order)=>{
                        if (orders[i] == order.id){
                            payment_made_at = order.payment_made_at;
                            delivered_at = order.delivered_at;
                            canceled_at = order.canceled_at;
                        }
                    });

                    formData.push({
                        order_id: orders [i],
                        payment_made_at: payment_made_at,
                        delivered_at: delivered_at,
                        canceled_at: canceled_at
                    });

                }

                await this.updateOrders(formData).then((orders)=>{

                    orders.forEach((order)=>{
                        this.orderUpdates = this.orderUpdates.filter(function(_order){
                            return _order != order.id;
                        });
                    });
                    this.$toastr.s('Orders updated successfully.');
                }).catch((err)=>{
                    this.$toastr.e(err);
                });
            },
            addUpdates(id){
                let pass = true;

                this.orderUpdates.forEach((orderId) => {
                    if (id == orderId){
                        pass = false;
                    }
                });

                if (pass){
                    this.orderUpdates.push(id);
                }

            }
        }

    }
</script>

<style scoped>

</style>