import axios, * as others from "../../../axios";
import * as Vue from "vue";

const state = {
  templates: [],
  template: {},
};
const mutations = {
  SET_TEMPLATES: (state, payload) => (state.templates = payload),
  SET_TEMPLATE: (state, payload) => (state.template = payload),
  UPDATE_TEMPLATE: (state, template) => {
    const i = state.templates
      .map((template) => template.id)
      .indexOf(template.id);
    state.templates.splice(i, 1);
    state.templates.splice(i, 0, template);
  },
  DELETE_TEMPLATE: (state, id) => {
    const i = state.templates.map((template) => template.id).indexOf(id);
    state.templates.splice(i, 1);
  },

  SET_ADVERT: (state, advert) => (state.template.advert = advert),
  REMOVE_ADVERT: (state) => (state.template.advert = null),
};
const actions = {
  async fetchTemplates({ commit }) {
    
    await axios
      .get("/templates/")
      .then((response) => {
        commit("SET_TEMPLATES", response.data);
      })
      .catch((error) => {
        const response = error.response;
        response && response.data
          ? this._vm.$toastr.e(response.data.message)
          : console.log(error);
      });
  },
  async fetchTemplate({ commit }, id) {
    state.template = {};
    await axios
      .get("/templates/" + id)
      .then((response) => {
        commit("SET_TEMPLATE", response.data);
      })
      .catch((error) => {
        const response = error.response;
        response && response.data
          ? this._vm.$toastr.e(response.data.message)
          : console.log(error);
      });
  },

  async storeTemplate({ commit }, formData) {
    return new Promise((resolve, reject) => {
      axios
        .post("/shop-owner/templates", formData)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          error.response ? reject(error.response) : console.log(error);
        });
    });
  },

  async updateTemplate({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/shop-owner/templates/" + data.id, data.formData)
        .then((response) => {
          commit("UPDATE_TEMPLATE", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          error.response ? reject(error.response) : console.log(error);
        });
    });
  },

  async deleteTemplate({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/shop-owner/templates/" + id)
        .then((response) => {
          commit("DELETE_TEMPLATE", id);
          resolve(response.data);
        })
        .catch((error) => {
          error.response ? reject(error.response) : console.log(error);
        });
    });
  },

  setTemplateAdvert({ commit }, advert) {
    commit("SET_ADVERT", advert);
  },

  removeTemplateAdvert({ commit }) {
    commit("REMOVE_ADVERT");
  },
};
const getters = {
  templates: (state) => state.templates,
  template: (state) => state.template,
};

const templateModule = {
  state,
  mutations,
  actions,
  getters,
};

export default templateModule;
