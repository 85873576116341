<template>
    <div class="wrapper">

        <!-- <div v-if="loading" class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div> -->

        <Layout v-show="!loginPage && !registerPage"></Layout>

        <router-view></router-view>

    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import Layout from "./components/Layout"
    import {mapActions} from "vuex";

    export default {
        name: "AppShopOwner",
        components: {Layout},
        data(){
            return{
                loading: false,
            }
        },
        computed:{
          ...mapGetters(['shopOwner']),
          loginPage(){
              return (this.$route.path === '/login')
          },
          registerPage(){
              return (this.$route.path === '/register')
          }
        },
        created() {
            this.loading = true;
        },
        mounted(){
            this.loading = false;
            
        },
        methods:{
            ...mapActions([

            ]),
        }
    }
</script>

<style scoped>

</style>