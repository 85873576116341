<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">Service List</h1>
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="'/home'">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Service List</li>
            </ol>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">
                  Service List
                  <span class="badge badge-info right">{{
                    filteredServices.length
                  }}</span>
                </h3>

                <div class="card-tools">
                  <div class="input-group input-group-sm" style="width: 150px;">
                    <div class="input-group-append">
                      <button
                        v-if="listPlus"
                        v-on:click="listPlus = false"
                        class="btn btn-outline-primary"
                      >
                        <i class="fas fa-list"></i>
                      </button>
                      <button
                        v-else
                        v-on:click="listPlus = true"
                        type="submit"
                        class="btn btn-outline-primary"
                      >
                        <i class="fas fa-image"></i>
                      </button>
                    </div>
                    <input
                      type="text"
                      v-model="searchString"
                      name="table_search"
                      class="form-control float-right"
                      placeholder="Search"
                    />

                    <div class="input-group-append">
                      <button type="submit" class="btn btn-default">
                        <i class="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                  <br />
                  <div class="input-group input-group-sm" style="width: 150px;">
                    <router-link
                      :to="'/add/service'"
                      class="btn btn-outline-primary float-right"
                    >
                      <i class="fas fa-plus"></i> Add Service
                    </router-link>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div v-if="listPlus" class="card-body pb-0">
                <div class="row d-flex align-items-stretch">
                  <div
                    v-for="service in filteredServices"
                    v-bind:key="service.id"
                    class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch"
                  >
                    <div class="card bg-light">
                      <div class="card-header text-muted border-bottom-0"></div>
                      <div class="card-body pt-0">
                        <div class="row">
                          <div class="col-7">
                            <h2 class="lead">
                              <b>{{ service.name }}</b>
                            </h2>
                            <p class="text-muted text-sm">
                              <b>Description: </b>
                              <span v-if="service.description"
                                >{{
                                  service.description.substring(0, 50)
                                }}...</span
                              >
                            </p>
                            <ul class="ml-4 mb-0 fa-ul text-muted">
                              <li class="small">
                                <span class="fa-li"
                                  ><i class="fas fa-lg fa-image"></i
                                ></span>
                                Images:
                                <span v-if="service.images">{{
                                  service.images.length
                                }}</span>
                              </li>
                            </ul>
                          </div>
                          <div class="col-5 text-center">
                            <img
                              v-if="service.images[0]"
                              :src="serverUrl + service.images[0].thumbUrl"
                              alt=""
                              class="img-circle img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="card-footer">
                        <div class="text-right">
                          <router-link
                            :to="'/show/service/' + service.id"
                            class="btn btn-sm btn-primary"
                          >
                            <i class="fas fa-eye"></i> View
                          </router-link>
                          <router-link
                            :to="'/edit/service/' + service.id"
                            class="btn btn-sm bg-teal"
                          >
                            <i class="fas fa-edit"></i> Edit
                          </router-link>
                          <a
                            href="#"
                            class="btn btn-sm btn-danger"
                            v-on:click="removeService(service.id)"
                          >
                            <i class="fas fa-trash"></i> Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /.card-body -->
              </div>
              <div v-else class="card-body table-responsive p-0">
                <table class="table table-hover text-nowrap">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Price</th>
                      <th>Discount</th>
                      <th>Added at</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="service in filteredServices"
                      v-bind:key="service.id"
                    >
                      <td>
                        <router-link :to="'/show/service/' + service.id">{{
                          service.name
                        }}</router-link>
                      </td>
                      <td>{{ service.price }} ETB</td>
                      <td>{{ service.discount }} ETB</td>
                      <td>{{ getDate(service.created_at) }}</td>
                      <td>
                        <router-link
                          :to="'/edit/service/' + service.id"
                          class="btn btn-sm bg-teal"
                        >
                          <i class="fas fa-edit"></i
                        ></router-link>
                        <button
                          class="btn btn-sm btn-danger"
                          v-on:click="removeService(service.id)"
                        >
                          <i class="fas fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ServiceList",
  data: function() {
    return {
      searchString: "",
      listPlus: false,
    };
  },
  props: {},
  computed: {
    ...mapGetters(["shopOwner", "services", "getDate", "serverUrl"]),

    filteredServices() {
      if (this.services) {
        return this.services.filter((service) => {
          return !service.name
            .toUpperCase()
            .search(this.searchString.toUpperCase());
        });
      } else {
        return [];
      }
    },
  },
  mounted() {
    this.fetchServices();
  },
  methods: {
    ...mapActions([
      "fetchServices",
      "deleteService",
      "showSuccessFlashMessage",
      "showFlashMessage",
    ]),
    async removeService(id) {
      bootbox.confirm(
        "Are you sure to delete the service and all its related data?",
        async (result) => {
          if (result) {
            await this.deleteService(id)
              .then((res) => {
                this.$toastr.s("Service removed successfully.");
              })
              .catch((err) => {
                this.$toastr.e(err);
              });
          }
        }
      );
    },
  },
};
</script>

<style scoped></style>
