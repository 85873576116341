<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">Category List</h1>
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="'/home'">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Category List</li>
            </ol>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">
                  Categories List
                  <span class="badge badge-info right">{{
                    filteredCategories.length
                  }}</span>
                </h3>

                <div class="card-tools">
                  <div class="input-group input-group-sm" style="width: 150px;">
                    <div class="input-group-append">
                      <button
                        v-if="listPlus"
                        v-on:click="listPlus = false"
                        class="btn btn-outline-primary"
                      >
                        <i class="fas fa-list"></i>
                      </button>
                      <button
                        v-else
                        v-on:click="listPlus = true"
                        type="submit"
                        class="btn btn-outline-primary"
                      >
                        <i class="fas fa-image"></i>
                      </button>
                    </div>
                    <input
                      type="text"
                      v-model="searchString"
                      name="table_search"
                      class="form-control float-right"
                      placeholder="Search"
                    />

                    <div class="input-group-append">
                      <button type="submit" class="btn btn-default">
                        <i class="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                  <br />
                  <div class="input-group input-group-sm" style="width: 150px;">
                    <router-link
                      :to="'/add/category'"
                      class="btn btn-outline-primary float-right"
                    >
                      <i class="fas fa-plus"></i> Add Category
                    </router-link>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div v-if="listPlus" class="card-body pb-0">
                <div class="row d-flex align-items-stretch">
                  <div
                    v-for="category in filteredCategories"
                    v-bind:key="category.id"
                    class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch"
                  >
                    <div class="card bg-light">
                      <div class="card-header text-muted border-bottom-0"></div>
                      <div class="card-body pt-0">
                        <div class="row">
                          <div class="col-7">
                            <h2 class="lead">
                              <b>{{ category.name }}</b>
                            </h2>
                            <p class="text-muted text-sm">
                              <b>Description: </b>
                              <span v-if="category.description"
                                >{{
                                  category.description.substring(0, 50)
                                }}...</span
                              >
                            </p>
                            <ul class="ml-4 mb-0 fa-ul text-muted">
                              <li class="small">
                                <span class="fa-li"
                                  ><i class="fas fa-lg fa-image"></i
                                ></span>
                                Images:
                                <span v-if="category.images">{{
                                  category.images.length
                                }}</span>
                              </li>
                              <li class="small">
                                <span class="fa-li"
                                  ><i class="fas fa-lg fa-chart-pie"></i
                                ></span>
                                Adverted: <span v-if="category.advert">Yes</span
                                ><span v-else>No</span>
                              </li>
                            </ul>
                          </div>
                          <div class="col-5 text-center">
                            <img
                              v-if="category.images[0]"
                              :src="serverUrl + category.images[0].thumbUrl"
                              alt=""
                              class="img-circle img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="card-footer">
                        <div class="text-right">
                          <router-link
                            :to="'/show/category/' + category.id"
                            class="btn btn-sm btn-primary"
                          >
                            <i class="fas fa-eye"></i> View
                          </router-link>
                          <router-link
                            :to="'/edit/category/' + category.id"
                            class="btn btn-sm bg-teal"
                          >
                            <i class="fas fa-edit"></i> Edit
                          </router-link>
                          <a
                            v-on:click="removeCategory(category.id)"
                            href="#"
                            class="btn btn-sm btn-danger"
                          >
                            <i class="fas fa-trash"></i> Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /.card-body -->
              </div>
              <div v-else class="card-body table-responsive p-0">
                <table class="table table-hover text-nowrap">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Added at</th>
                      <th>Adverted</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="category in filteredCategories"
                      v-bind:key="category.id"
                    >
                      <td>
                        <router-link :to="'/show/category/' + category.id">{{
                          category.name
                        }}</router-link>
                      </td>
                      <td>{{ getDate(category.created_at) }}</td>
                      <td>
                        <span v-if="category.advert">Yes</span
                        ><span v-else>No</span>
                      </td>
                      <td>
                        <router-link
                          :to="'/edit/category/' + category.id"
                          class="btn btn-sm bg-teal"
                        >
                          <i class="fas fa-edit"></i></router-link
                        ><button
                          v-on:click="removeCategory(category.id)"
                          class="btn btn-sm btn-danger"
                        >
                          <i class="fas fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CategoryList",
  data: function() {
    return {
      searchString: "",
      listPlus: false,
    };
  },
  props: {},

  computed: {
    ...mapGetters(["getDate", "categories", "serverUrl"]),

    filteredCategories() {
      if (this.categories.length > 0) {
        return this.categories.filter((category) => {
          return !category.name
            .toUpperCase()
            .search(this.searchString.toUpperCase());
        });
      } else {
        return [];
      }
    },
  },
  mounted() {
    this.fetchCategories();
  },
  methods: {
    ...mapActions([
      "fetchCategories",
      "deleteCategory",
      "showSuccessFlashMessage",
      "showFlashMessage",
    ]),
    async removeCategory(id) {
      bootbox.confirm(
        "Are you sure to delete the category and all its related data (products, tags)?",
        async (result) => {
          if (result) {
            await this.deleteCategory(id)
              .then((res) => {
                this.$toastr.s("Category removed successfully.");
              })
              .catch((err) => {
                this.$toastr.e(err);
              });
          }
        }
      );
    },
  },
};
</script>

<style scoped></style>
